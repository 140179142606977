/* eslint-disable no-restricted-globals */
import moment from "moment";
// eslint-disable-next-line no-restricted-imports
import { useReadLocalStorage as useReadLocalStorageInternal } from "usehooks-ts";

import { BTLoginTypes, EntityTypes } from "types/enum";

import { ISawmillEvent } from "utilities/analytics/customPlugins/sawmillPlugin";
import { isUnitTest } from "utilities/environment/environment";
import { reportError } from "utilities/errorHelpers";

import { IOAuthStateParamState } from "commonComponents/auth/AuthStoreDiscovery.types";
import { IEditorApiType } from "commonComponents/btWrappers/editor/editor.types";
import { IAutoDismissEmptyState } from "commonComponents/entity/emptyState/common/EmptyState.api.types";
import {
    DEFAULT_GRID_WIDTH,
    GanttColumnType,
    GanttZoomLevels,
    IGanttChartLocalSettings,
} from "commonComponents/entity/schedule/GanttChart/GanttChart.types";
import { IProcessStatusGroupState } from "commonComponents/utilities/ClientObservableBackgroundJobStatusPopup/ClientObservableBackgroundJobStatusPopup.types";
import { IHeaderInfoResponse } from "commonComponents/utilities/HeaderInfo/OwnerHeaderInfo.api.types";
import { IJobPickerPersistantState } from "commonComponents/utilities/JobPicker/JobPicker.types";
import { GlobalSearchResult } from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearch.types";
import { ISearchBarResultResponse } from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.api.types";

import { ExpectedBudgetReportOption } from "entity/budget/Budget/BudgetContainer/BudgetContainer.api.types";
import { IOAuthStateValues } from "entity/login/Login/Login.api.types";
import { SelectedMediaListViews } from "entity/media/common/mediaTypes";
import {
    NotificationPermissionPromptState,
    PromptState,
} from "entity/notification/notification.types";
import { ICachedReceipt } from "entity/rebateReceiptReview/hooks/useCachedReceipts";
import { ITradeAgreementSessionValues } from "entity/tradeAgreement/TradeAgreement/TradeAgreement.api.types";

// todo - remove this, these will all be migrated to ILocalStorage
export enum LocalStorageKeys {
    MainNavigationData = "mainNavigationData",
    CurrentJobPickerFilter = "currentJobPickerFilter",
    SelectedJobPickerFilterId = "selectedJobPickerFilterId",
    JobPickerKeywordSearch = "jobPickerKeywordSearch",
    GanttChartLocalSettings = "ganttChartLocalSettings",
    DynamicIntercomKey = "intercom.intercom-state-",
}

interface IFilterPreferences {
    /**
     * true if panel is open, false if panel is closed
     * undefined means user has no preferences saved
     */
    [key: number]: boolean | undefined;
}

interface IPageSizePreferences {
    /**
     * key should be ListEntityType
     */
    [Key: number]: number | undefined;
}

export interface IProcessStatusStorage {
    /**
     * key should be ProcessStatusGroupType
     */
    [Key: number]: IProcessStatusGroupState;
}

/**
 * Note to devs: do NOT store classes in LocalStorage, use interfaces instead.
 * Reminder only some data can be stringified and stored (see detectDataThatCantBeStored for more info).
 * See https://btwiki.atlassian.net/wiki/spaces/dv/pages/3366355107/Browser+Storage+Patterns for more details
 */
export interface ILocalStorage {
    "bt-boolean-btMFAWarningBannerDismissed": boolean;
    "bt-boolean-hideScheduleAlerts": boolean;
    "bt-boolean-logoCollapsed": boolean;
    "bt-boolean-redirectedToJobNotInJobPicker": boolean | null;
    "bt-boolean-showFolderDetails": boolean;
    "bt-boolean-showSchedulePhases": boolean;
    "bt-boolean-hideEditorDebug": boolean;
    "bt-boolean-obpDontShowAgain": boolean;
    // ATs set this to true to force-disable analytics
    "bt-boolean-disableAllAnalytics": boolean | null;
    "bt-boolean-hasGivenFergusonMarketingFeedback": boolean | null;
    "bt-boolean-hasGivenBuildMarketingFeedback": boolean | null;
    "bt-boolean-hasGivenEnterpriseMarketingFeedback": boolean | null;
    "bt-boolean-hasUnreadNotifications": boolean | null;
    "bt-number-lastOauthBuilderSelected": number | null;
    "bt-number-recentLoginType": BTLoginTypes | undefined;
    "bt-number-sidebarWidth": number;
    "bt-number-unreadNotificationCount": number | null;
    "bt-numberArray-hiddenBaselineAlertJobIds": number[];
    "bt-object-autoDismissEmptyState": IAutoDismissEmptyState;
    "bt-object-filterPreferences": IFilterPreferences;
    "bt-object-ganttChartLocalSettings": IGanttChartLocalSettings;
    "bt-object-headerInfo": IHeaderInfoResponse | null;
    "bt-object-intercomShowChat": { value: boolean; expires: number };
    "bt-object-pageSizePreferences": IPageSizePreferences;
    "bt-object-clientObservableProcessStatusStorage": IProcessStatusStorage;
    "bt-object-chatDraftValues": Record<string, IEditorApiType>;
    /**
     * ado-178532 we had massive event sizes put on this key which made it unsafe
     * @deprecated Use bt-objectArray-sawmillEventsV2
     */
    "bt-objectArray-sawmillEvents": ISawmillEvent[];
    "bt-objectArray-sawmillEventsV2": ISawmillEvent[];
    "bt-objectArray-searchBarRecentResults": ISearchBarResultResponse[];
    "bt-objectArray-globalSearchRecentlyViewed": GlobalSearchResult[];
    "bt-string-jobPickerPreference": "open" | "closed";
    "bt-string-monthViewPreference": "collapsed" | "expanded";
    "bt-string-selectedDocumentListView": SelectedMediaListViews;
    "bt-string-selectedPhotoListView": SelectedMediaListViews;
    "bt-string-selectedVideoListView": SelectedMediaListViews;
    "bt-string-selectedBrowseBTListView": SelectedMediaListViews;
    "bt-string-selectedViewAllAttachmentsListView": SelectedMediaListViews;
    "bt-object-showMoreState": {
        [key in EntityTypes]?: boolean;
    };
    "bt-object-notificationPermissionsPromptState": NotificationPermissionPromptState;
    "bt-object-btCollapsePrefs": { [key: string]: { values: string[] } } | {};

    // Eventually, post SPA, these should be moved to session storage to support multi-tab better
    "bt-object-dangerousJobPickerState": IJobPickerPersistantState;
    "bt-string-paymentVerificationOauthRedirectUrl": string | null;
    "bt-string-paymentServiceAuthToken": string | null;
    "bt-object-oauthRedirectState": IOAuthStateValues | null;
    "bt-boolean-hasUnreadChat": boolean | null;
    "bt-numberArray-jobIDsToPrint": number[] | null;
    "bt-numberArray-proposalCollapsedGroups": number[];
    "bt-object-tableSettings": { [key: string]: { padding: number; fontSize: number } } | {};
    "bt-number-expectedBudgetReportOption": ExpectedBudgetReportOption | null;
    "bt-boolean-debugReactQuery": boolean | null;
    "bt-boolean-showOwnerInvoiceEditOptions": boolean | null;
    "bt-boolean-hidePostBTPaymentsBanner": boolean | null;
    "bt-number-selectedProposalTemplateID": string | null;
    "bt-boolean-hideOutOfSyncAcctBanner": boolean | null;
    "bt-objectArray-previouslyViewedReceipts": ICachedReceipt[];
    "bt-objectArray-recentlyReviewReceipts": ICachedReceipt[];
    "bt-numberArray-proposalFavoritedOptions": number[];
    "bt-object-oAuthStateParam": IOAuthStateParamState | null;
    "bt-string-newAppDetectedDate": number | null;
}

export const localStorageDefaultValues: ILocalStorage = {
    "bt-boolean-btMFAWarningBannerDismissed": false,
    "bt-boolean-hideScheduleAlerts": false,
    "bt-boolean-logoCollapsed": window.innerHeight < 800,
    "bt-boolean-redirectedToJobNotInJobPicker": null,
    "bt-boolean-showFolderDetails": true,
    "bt-boolean-showSchedulePhases": false,
    "bt-boolean-hideEditorDebug": false,
    "bt-boolean-obpDontShowAgain": false,
    "bt-boolean-disableAllAnalytics": null,
    "bt-boolean-hasGivenFergusonMarketingFeedback": null,
    "bt-boolean-hasGivenBuildMarketingFeedback": null,
    "bt-boolean-hasGivenEnterpriseMarketingFeedback": null,
    "bt-boolean-hasUnreadNotifications": null,
    "bt-number-lastOauthBuilderSelected": null,
    "bt-number-recentLoginType": undefined,
    "bt-number-sidebarWidth": 250,
    "bt-number-unreadNotificationCount": null,
    "bt-boolean-hasUnreadChat": null,
    "bt-numberArray-hiddenBaselineAlertJobIds": [],
    "bt-object-autoDismissEmptyState": {},
    "bt-object-filterPreferences": {},
    "bt-object-dangerousJobPickerState": {
        keywordSearch: "",
        filters: "",
        templateFilters: "",
        isTemplateMode: false,
        selectedJobIds: [],
        isAllJobsSelected: false,
        allBuildersSelected: false,
    },
    "bt-object-ganttChartLocalSettings": {
        showCriticalPath: false,
        showBaseline: false,
        showJobs: false,
        showPhases: false,
        zoomLevel: GanttZoomLevels.Day,
        gridWidth: DEFAULT_GRID_WIDTH,
        columns: [
            GanttColumnType.Title,
            GanttColumnType.Job,
            GanttColumnType.StartDate,
            GanttColumnType.Workdays,
            GanttColumnType.QuickEdit,
            GanttColumnType.NewSchedule,
        ],
    },
    "bt-object-headerInfo": null,
    "bt-object-intercomShowChat": { value: false, expires: 0 },
    "bt-object-pageSizePreferences": {},
    "bt-object-clientObservableProcessStatusStorage": {},
    "bt-object-chatDraftValues": {},
    "bt-objectArray-sawmillEvents": [],
    "bt-objectArray-sawmillEventsV2": [],
    "bt-objectArray-searchBarRecentResults": [],
    "bt-objectArray-globalSearchRecentlyViewed": [],
    "bt-string-jobPickerPreference": "open",
    "bt-string-monthViewPreference": "collapsed",
    "bt-string-selectedDocumentListView": "Table",
    "bt-string-selectedPhotoListView": "Tile",
    "bt-string-selectedVideoListView": "Tile",
    "bt-string-selectedBrowseBTListView": "Table",
    "bt-string-selectedViewAllAttachmentsListView": "Table",
    "bt-object-showMoreState": {
        [EntityTypes.Schedule]: false,
    },
    "bt-object-notificationPermissionsPromptState": {
        state: PromptState.New,
    },
    "bt-string-paymentVerificationOauthRedirectUrl": null,
    "bt-string-paymentServiceAuthToken": null,
    "bt-object-oauthRedirectState": null,
    "bt-numberArray-jobIDsToPrint": [],
    "bt-numberArray-proposalCollapsedGroups": [],
    "bt-object-tableSettings": {},
    "bt-number-expectedBudgetReportOption": null,
    "bt-boolean-debugReactQuery": null,
    "bt-boolean-showOwnerInvoiceEditOptions": true,
    "bt-object-btCollapsePrefs": {},
    "bt-boolean-hidePostBTPaymentsBanner": false,
    "bt-number-selectedProposalTemplateID": null,
    "bt-boolean-hideOutOfSyncAcctBanner": false,
    "bt-objectArray-previouslyViewedReceipts": [],
    "bt-objectArray-recentlyReviewReceipts": [],
    "bt-numberArray-proposalFavoritedOptions": [],
    "bt-object-oAuthStateParam": null,
    "bt-string-newAppDetectedDate": null,
};

/**
 * Note to devs: do NOT store classes in SessionStorage, use interfaces instead.
 * Reminder only some data can be stringified and stored (see detectDataThatCantBeStored for more info).
 * See https://btwiki.atlassian.net/wiki/spaces/dv/pages/3366355107/Browser+Storage+Patterns for more details
 */
export interface ISessionStorage {
    "bt-boolean-hasViewedTradeAgreement": boolean;
    "bt-boolean-hideEditorDebug": boolean;
    "bt-numberArray-photoPreviewListOfDocInstanceIds": number[];
    "bt-numberArray-photoPreviewListOfJobIds": number[];
    "bt-object-tradeAgreementValues": ITradeAgreementSessionValues | null;
    "bt-object-dangerousJobPickerState": IJobPickerPersistantState | null;
    "bt-object-notificationPermissionsPromptState": NotificationPermissionPromptState;
    "bt-string-lastTabTitleBeforeTemporaryTitle": string | null;
}
const sessionStorageDefaultValues: ISessionStorage = {
    "bt-boolean-hasViewedTradeAgreement": false,
    "bt-boolean-hideEditorDebug": false,
    "bt-numberArray-photoPreviewListOfDocInstanceIds": [],
    "bt-numberArray-photoPreviewListOfJobIds": [],
    "bt-object-tradeAgreementValues": null,
    "bt-object-dangerousJobPickerState": null,
    "bt-object-notificationPermissionsPromptState": {
        state: PromptState.New,
    },
    "bt-string-lastTabTitleBeforeTemporaryTitle": null,
};

function isStorageSupported(): boolean {
    try {
        const storage = window.localStorage;
        storage.setItem("isStorageSupportedTest", "test value");
        storage.removeItem("isStorageSupportedTest");
        return true;
    } catch (e) {
        return false;
    }
}

const allowedTypes = {
    Object: true,
    Boolean: true,
    Array: true,
    Number: true,
    String: true,
};

const allowedTypeKeys = [
    "boolean",
    "number",
    "string",
    "object",
    "numberArray",
    "stringArray",
    "objectArray",
];

/**
 * JSON.stringify can only store some values, you should not store values in localstorage that can't be stored
 * For example you should never try and store the following: function, dates, Infinity, Set, Map, BigInt, ...
 * Also make sure you store a base object instead of a class. Only properties of the class can be stored
 */
function detectDataThatCantBeStored(this: any, key: any, value: any) {
    // classes can have a custom toJSON method that is called BEFORE this method is run (example Date). We want to filter values before toJSON is run - https://stackoverflow.com/questions/21034760/strange-behaviour-in-json-stringify-with-replacer-function
    const rawValue = this[key];
    const isEmpty = rawValue === undefined || rawValue === null;
    const isPrimitiveType = allowedTypes[rawValue?.constructor?.name] === true;
    const isPlainObjectWithoutConstructor =
        typeof rawValue === "object" && rawValue !== null && !("constructor" in rawValue);
    const isSupportedType =
        isEmpty || isPrimitiveType || isPlainObjectWithoutConstructor || moment.isMoment(rawValue);

    if (!isSupportedType) {
        throw new Error(
            `Attempted to store data in localstorage that cannot be stringified - key: ${key}, value: ${rawValue} - if you are trying to use a class instead use a plain object. LocalStorage cannot store complex types like classes, dates, or functions`
        );
    }

    return value;
}

const throwDevelopmentError = (msg: string) => {
    if (isUnitTest() || import.meta.env.MODE === "development") {
        throw msg;
    }
};

function logErrorIfNeeded(error: any) {
    const storageSupported = isStorageSupported();

    console.error(error);
    if (storageSupported) {
        // storage is supported, but we got an exception, report it
        reportError(error);
    }
    throwDevelopmentError(error);
}

export const checkFullyQualifiedKey = (key: string) => {
    const keyTokens = key.split("-");
    if (keyTokens.length !== 3) {
        logErrorIfNeeded(
            `Key [${key}] did not conform to proper naming convention of "bt-<type>-<key>"`
        );
        return;
    }

    const [prefix, typeString] = keyTokens;
    if (prefix !== "bt") {
        logErrorIfNeeded(`Key [${key}] did not start with "bt-"`);
        return;
    }
    if (!allowedTypeKeys.includes(typeString)) {
        logErrorIfNeeded(
            `Key [${key}] did not include one of the allowed types: [${allowedTypeKeys.join(", ")}]`
        );
    }
};

// todo have util to mock out local storage and clear it automatically between each test
/**
 * A wrapper around localstorage/sessionstorage - this wrapper provides a strongly typed way of accessing storage
 * @example
 * // storage item will be loaded, if no item exists or the user has storage off the default will be returned
 * const thing = Storage.get("JobPickerPreference");
 */
class BTStorage<StorageType> {
    constructor(storageType: Storage, defaultValues: StorageType) {
        this.storage = storageType;
        this.defaultValues = defaultValues;
        this.storageListeners = new WeakMap<any, any>();
    }

    private storage: Storage;
    private defaultValues: StorageType;
    private storageListeners: WeakMap<any, any>;

    get<Key extends keyof StorageType>(key: Key): StorageType[Key] {
        checkFullyQualifiedKey(key.toString());
        try {
            const value = this.storage.getItem(key as string);

            if (value === null) {
                return this.defaultValues[key];
            }
            return value === "undefined" ? undefined : JSON.parse(value);
        } catch (error) {
            if (key === "jobPickerPreference") {
                // could not parse jobPickerPreference because it was not stored as a serialized string
                // todo remove once react jobpicker is fully live
                this.storage.removeItem("jobPickerPreference");
                return this.defaultValues[key];
            }

            logErrorIfNeeded(error);
            return this.defaultValues[key];
        }
    }

    set<Key extends keyof StorageType>(key: Key, value: StorageType[Key]): void {
        checkFullyQualifiedKey(key.toString());
        // placed outside of the try so this will throw when devs store non-serializable data
        const newValue = JSON.stringify(value, detectDataThatCantBeStored);

        // we have made the decision to not polyfill localstorage until (if) it becomes a larger issue
        // if we cant store items we will simply ignore the save and continue to return the default value
        try {
            const oldValue = this.get(key);
            this.storage.setItem(key as string, newValue);

            // Some browsers do not trigger storage events within the same window. Manually trigger the event
            // see addChangeListener/removeChangeListener for more info
            window.dispatchEvent(
                new StorageEvent("storage", {
                    key: key as string,
                    storageArea: this.storage,

                    // to match the existing events we need to stringify the old key
                    oldValue: JSON.stringify(oldValue),
                    newValue: newValue,
                    url: document.URL,
                })
            );
        } catch (error) {
            // todo catch QuotaExceededError - the user has storage turned off - don't log
            // todo detect when the user has hit the storage quota (most of the time 5MB)
            logErrorIfNeeded(error);
        }
    }

    /**
     * Fires a change even when the storage key is changed. Useful for listening to storage change from other tabs
     */
    addChangeListener<Key extends keyof StorageType>(
        key: Key,
        callback: (oldValue: StorageType[Key], newValue: StorageType[Key]) => void
    ): void {
        const filteredCallback = (event: StorageEvent) => {
            // only fire callback if the key is the item being changed
            if (event.key === key) {
                const oldValueParsed: StorageType[Key] =
                    event.oldValue === null ? this.defaultValues[key] : JSON.parse(event.oldValue);
                const newValueParsed: StorageType[Key] =
                    event.newValue === null ? this.defaultValues[key] : JSON.parse(event.newValue);

                callback(oldValueParsed, newValueParsed);
            }
        };

        // we store a reference to the generated wrapped callback so we can remove it later if need be
        this.storageListeners.set(callback, filteredCallback);
        window.addEventListener("storage", filteredCallback);
    }

    /**
     * Removes a existing storage change listener
     */
    removeChangeListener<Key extends keyof StorageType>(
        key: Key,
        callback: (oldValue: StorageType[Key], newValue: StorageType[Key]) => void
    ): void {
        const filteredCallback = this.storageListeners.get(callback);

        window.removeEventListener("storage", filteredCallback);
    }

    /**
     * Removes a item from local storage
     * @param key The item to remove
     */
    remove<Key extends keyof StorageType>(key: Key): void {
        checkFullyQualifiedKey(key.toString());
        try {
            this.storage.removeItem(key as string);
        } catch (error) {
            logErrorIfNeeded(error);
        }
    }

    /**
     * Remove intercom item(s) from localstorage. See ADO-36156, ADO-36163.
     */
    removeLocalStorageItems(key: LocalStorageKeys): void {
        for (const itemKey in this.storage) {
            if (itemKey.includes(key)) {
                this.storage.removeItem(itemKey);
            }
        }
    }
}

export const BTLocalStorage = new BTStorage<ILocalStorage>(localStorage, localStorageDefaultValues);

export const BTSessionStorage = new BTStorage<ISessionStorage>(
    sessionStorage,
    sessionStorageDefaultValues
);

export function useReadLocalStorage<Key extends keyof ILocalStorage>(
    key: Key
): ILocalStorage[Key] | null {
    return useReadLocalStorageInternal<ILocalStorage[Key]>(key);
}
