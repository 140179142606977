import { datadogRum } from "@datadog/browser-rum";
import moment from "moment";
import { Blue9 } from "styles/antTheme/Colors";

import { AccountingIntegrationType, BTLoginTypes, JobDescriptionType } from "types/enum";
import UserTabPermissions from "types/UserTabPermissions/UserTabPermissions";

import { getCurrentEnvironment } from "utilities/environment/environment";

import {
    IAlert,
    IHeaderAlertExtraData,
} from "commonComponents/utilities/HeaderAlerter/HeaderAlerter.types";
import { IntercomSettings } from "commonComponents/utilities/IntercomChat/IntercomTypes";

import { default as AppProviderAPI } from "./AppProvider.api.json";

export class GlobalInfo {
    constructor(data: typeof AppProviderAPI) {
        this.thirdPartyInfo = new ThirdPartyInfo(data.thirdPartyInfo);
        this.environmentInfo = new EnvironmentInfo(data.environmentInfo);
        this.appDefaultInfo = new AppDefaultInfo((data as any).appDefaultInfo);
        this.userInfo = new UserInfo(data.userInfo);

        this.builderInfo = data.builderInfo ? new BuilderInfo(data.builderInfo) : null;
        this.integrationBuilderProfile = data.integrationBuilderProfile
            ? new IntegrationBuilderProfile(data.integrationBuilderProfile)
            : null;
        this.supportContactInfo = (data as any).supportContactInfo
            ? new SupportContactInfo((data as any).supportContactInfo)
            : null;
        this.brandingInfo = data.brandingInfo ? new BrandingInfo(data.brandingInfo) : null;
        this.integrationUserProfile = data.integrationUserProfile
            ? new IntegrationUserProfile(data.integrationUserProfile)
            : null;
    }

    // All
    thirdPartyInfo: ThirdPartyInfo;
    appDefaultInfo: AppDefaultInfo;
    environmentInfo: EnvironmentInfo;
    userInfo: UserInfo;

    // Requires builder in session
    builderInfo: BuilderInfo | null;
    supportContactInfo: SupportContactInfo | null;
    brandingInfo: BrandingInfo | null;
    integrationBuilderProfile: IntegrationBuilderProfile | null;

    // Requires user in session
    integrationUserProfile: IntegrationUserProfile | null;
}

export class SpaInfo {
    isSpa: boolean | null;
}

export class BrandingInfo {
    constructor(data: typeof AppProviderAPI.brandingInfo) {
        this.primaryColor = data.primaryColor;
    }
    primaryColor: string;
}

export class UserInfo {
    constructor(data: typeof AppProviderAPI.userInfo) {
        this.timeoutMinutes = data.timeoutMinutes;
        this.globalUserId = data.globalUserId;
        this.orgLinks = (data.orgLinks ?? []).map((orgLink) => new OrgLinkInfo(orgLink));
        this.userName = data.userName;
        this.fullName = data.fullName;
        this.firstName = data.firstName;
        this.loginType = data.loginType;
        this.isImpersonatingBuilder = data.isImpersonatingBuilder;
        this.credentialLoginType = data.credentialLoginType;
        this.isAdmin = data.isAdmin;
        this.isBuilderImpersonatingOwner = data.isBuilderImpersonatingOwner;
        this.systemAlerts = data.systemAlerts;
        this.intercomSettings = data.intercomSettings
            ? new IntercomSettings(data.intercomSettings)
            : null;
        this.roleInfo = data.roleInfo ? new RoleInfo(data.roleInfo) : null;
        this.shouldRedirectToSetupExperience = data.shouldRedirectToSetupExperience ?? false;
        this.hasAcceptedTermsAndPolicy = data.hasAcceptedTermsAndPolicy ?? false;
        this.permissions = data.permissions ? new UserTabPermissions(data.permissions) : null;
    }

    loginType: BTLoginTypes;
    timeoutMinutes: number;
    isImpersonatingBuilder: boolean;
    credentialLoginType: BTLoginTypes;
    isBuilderImpersonatingOwner: boolean;
    systemAlerts: IAlert<IHeaderAlertExtraData>[];

    // Only populated when user is in session
    globalUserId: number | null;
    orgLinks: OrgLinkInfo[] | null;
    userName: string | null;
    fullName: string | null;
    firstName: string | null;
    email: string | null;
    isAdmin: boolean | null;
    intercomSettings: IntercomSettings | null;
    roleInfo: RoleInfo | null;
    shouldRedirectToSetupExperience: boolean;
    hasAcceptedTermsAndPolicy: boolean;
    permissions: UserTabPermissions | null;
}

export class OrgLinkInfo {
    constructor(data: (typeof AppProviderAPI.userInfo.orgLinks)[0]) {
        this.orgId = data.orgId;
        this.orgLinkId = data.orgLinkId;
        this.orgName = data.orgName;
        this.isClosed = data.isClosed;
    }

    orgId: number;
    orgLinkId: number;
    orgName?: string;
    isClosed?: boolean;
}

export class RoleInfo {
    constructor(data: any) {
        this.jobDescription = data.jobDescription;
        this.roleName = data.roleName;
    }
    jobDescription: JobDescriptionType;
    roleName: string;
}

export class IntegrationUserProfile {
    constructor(data: typeof AppProviderAPI.integrationUserProfile) {
        this.userId = data.userId;
        this.username = data.username;
        this.fullName = data.fullName;
        this.firstName = data.firstName;
        this.isAdmin = data.isAdmin;
        this.isBtChampion = data.isBtChampion;
        this.userCreatedAt = data.userCreatedAt ? moment.unix(data.userCreatedAt) : null;
        this.userRole = data.userRole;
        this.hasActiveJobs = data.hasActiveJobs;
        this.canAddDailyLogs = data.canAddDailyLogs;
        this.canAddToDos = data.canAddToDos;
        this.canAddScheduleItems = data.canAddScheduleItems;
        this.canAddInternalUsers = data.canAddInternalUsers;
        this.canEditFiles = data.canEditFiles;
    }

    userId: number;
    username: string;
    fullName: string;
    firstName: string;
    isAdmin: boolean;
    isBtChampion: boolean;
    userCreatedAt: moment.Moment | null;
    userRole: string | null;
    hasActiveJobs: boolean;
    canAddDailyLogs: boolean;
    canAddToDos: boolean;
    canAddScheduleItems: boolean;
    canAddInternalUsers: boolean;
    canEditFiles: boolean;
}

export class IntegrationBuilderProfile {
    constructor(data: typeof AppProviderAPI.integrationBuilderProfile) {
        this.builderId = data.builderId;
        this.builderName = data.builderName;
        this.accountSetupDate = moment.unix(data.accountSetupDate);
        this.isConnectedToAccounting = data.isConnectedToAccounting;
        this.accountingIntegrationType = data.accountingIntegrationType;
        this.hasOnboardingContact = data.hasOnboardingContact;
        this.subscriptionPlan = data.subscriptionPlan;
        this.isPaymentProcessingLinked = data.isPaymentProcessingLinked;
        this.hasCustomCostCodes = data.hasCustomCostCodes;
        this.activeInternalUserCount = data.activeInternalUserCount;
    }

    builderId: number;
    builderName: string;
    accountSetupDate: moment.Moment;
    isConnectedToAccounting: boolean;
    accountingIntegrationType: AccountingIntegrationType;
    hasOnboardingContact: boolean;
    subscriptionPlan: string;
    isPaymentProcessingLinked: boolean;
    hasCustomCostCodes: boolean;
    activeInternalUserCount: number;
}

export class BuilderInfo {
    constructor(data: typeof AppProviderAPI.builderInfo) {
        this.encryptedBuilderId = data.encryptedBuilderId ?? null;
        this.builderId = data.builderId;
        this.name = data.name;
        this.weekStartDay = data.weekStartDay;
        this.scheduleDefaultTabForCurrentPortal = data.scheduleDefaultTabForCurrentPortal;
        this.selectionDefaultBuilderView = data.selectionDefaultBuilderView;
        this.activityCalendarDefaultView = data.activityCalendarDefaultView;
        this.isDemoAccount = data.isDemoAccount;
        this.locale = new BuilderLocale(data.locale);
        this.flags = new BuilderFlags((data as any).flags);
        this.taxesOptOut = data.taxesOptOut;
        this.isInTaxesSupportedRegion = data.isInTaxesSupportedRegion;
        this.setupDate = moment(data.setupDate);
        this.showAccountingBannerAd = data.showAccountingBannerAd;
        this.canUseExternalTemplates = data.canUseExternalTemplates ?? false;
        this.redirectToSetupExperience = data.redirectToSetupExperience ?? false;
        this.loginsDisabled = data.loginsDisabled ?? false;
    }
    encryptedBuilderId: string | null;
    builderId: number;
    name: string;
    weekStartDay: number;
    scheduleDefaultTabForCurrentPortal: number;
    selectionDefaultBuilderView: number;
    activityCalendarDefaultView: number;
    isDemoAccount: boolean;
    locale: BuilderLocale;
    flags: BuilderFlags;
    taxesOptOut: boolean;
    isInTaxesSupportedRegion: boolean;
    setupDate: moment.Moment;
    showAccountingBannerAd?: boolean;
    canUseExternalTemplates: boolean;
    redirectToSetupExperience: boolean;
    loginsDisabled: boolean;
}

export class BuilderLocale {
    constructor(data: typeof AppProviderAPI.builderInfo.locale) {
        this.currencyLocale = new CurrencyLocale(data.currencyLocale);
        this.dateLocale = new DateLocale(data.dateFormat);
        this.timeLocale = new TimeLocale(data.timeLocale);
    }
    currencyLocale: CurrencyLocale;
    dateLocale: DateLocale;
    timeLocale: TimeLocale;
}

export class TimeLocale {
    constructor(data: { is24HourClock: boolean; format: string; timeZoneName: string }) {
        this.timeZoneName = data.timeZoneName;
        this.is24HourClock = data.is24HourClock;
        this.format = data.format;
    }

    static default = new TimeLocale({
        is24HourClock: false,
        format: "h:mm a",
        timeZoneName: "CST",
    });

    timeZoneName: string;
    is24HourClock: boolean;
    format: string;
}

export class DateLocale {
    constructor(builderInputDateFormat: string) {
        const separators = ["-", ".", " ", "/"];
        // all values are used for parsing and first value is used for formatting
        // this allows users to type in their locale culture format (example: M-D-YYYY for USA)
        this.dateInputFormats = [
            this.dateFormat,
            "LL",
            ...separators.flatMap((separator) => {
                const withSeparator = builderInputDateFormat.replace(/[-./ ]/g, separator);
                const nonLeadingZero = withSeparator.replace("MM", "M").replace("DD", "D");
                const leadingZero = nonLeadingZero.replace("M", "MM").replace("D", "DD");
                return [nonLeadingZero, leadingZero];
            }),
        ];
    }

    static default = new DateLocale("MM-DD-YYYY");

    // These are all hardcoded for now and follow AP standards. If we ever want builder-specific
    // formats they should be provided server side
    dateFormat = "ll";
    dateTimeFormat = "ll HH:mm";
    weekFormat = "wo [week of] gggg";
    monthFormat = "MMM YYYY";

    /** List of acceptable format strings for date inputs */
    dateInputFormats: string[] | undefined;
}

export class CurrencyLocale {
    constructor(data: {
        currencySymbol: string;
        decimalSeparator: string;
        groupSeparator: string;
    }) {
        this.currencySymbol = data.currencySymbol;
        this.decimalSeparator = data.decimalSeparator;
        this.groupSeparator = data.groupSeparator;

        const currencySymbol = this.currencySymbol.trim();
        if (currencySymbol === "₹") {
            this.thousandsGroupStyle = "lakh";
        } else {
            this.thousandsGroupStyle = undefined;
        }
    }

    static default = new CurrencyLocale({
        currencySymbol: "$",
        decimalSeparator: ".",
        groupSeparator: ",",
    });

    currencySymbol: string;
    decimalSeparator: string;
    thousandsGroupStyle: undefined | "lakh" | "wan";
    groupSeparator: string;
}

export class ThirdPartyInfo {
    constructor(data: typeof AppProviderAPI.thirdPartyInfo) {
        this.googleMapsApiKey = data.googleMapsApiKey;
        this.sentryCurrentEnvironmentReleaseId = data.sentryCurrentEnvironmentReleaseId;
        this.firebaseConfig = data.firebaseConfig ? new FirebaseConfig(data.firebaseConfig) : null;
        this.sardineClientId = data.sardineClientId;
        this.sardineSessionKey = data.sardineSessionKey;
    }

    googleMapsApiKey: string;
    /** Can be null in certain environments, like dev */
    sentryCurrentEnvironmentReleaseId: string | null;
    firebaseConfig: FirebaseConfig | null;
    sardineClientId: string | null;
    sardineSessionKey: string | null;
}

export class FirebaseConfig {
    constructor(data: typeof AppProviderAPI.thirdPartyInfo.firebaseConfig) {
        this.apiKey = data.apiKey;
        this.authDomain = data.authDomain;
        this.databaseURL = data.databaseURL;
        this.projectId = data.projectId;
        this.storageBucket = data.storageBucket;
        this.messagingSenderId = data.messagingSenderId;
        this.appId = data.appId;
        this.measurementId = data.measurementId;
    }

    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
}

export class EnvironmentInfo {
    constructor(data: typeof AppProviderAPI.environmentInfo) {
        this.isProduction = data.isProduction;
        this.name = data.name;
        this.color = data.color;
        this.supportMobileEphemeralLogin = data.supportMobileEphemeralLogin;
        this.auth0Url = data.auth0Url;
        this.quartzPath = data.quartzPath;
        this.isPublicEph = data.isPublicEph;
        this.infraCreationDate = data.infraCreationDate
            ? new Date(data.infraCreationDate)
            : undefined;
    }

    isProduction: boolean;
    name: string;
    color: string;
    supportMobileEphemeralLogin: boolean;
    auth0Url: string;
    quartzPath: string;
    isPublicEph: boolean;
    infraCreationDate?: Date;
}

export class BuilderFlags {
    constructor(data: any | undefined) {
        // If data is undefined, assume that we are in the test environment
        // The default flag values are assumed
        if (data === undefined) {
            return;
        }

        Array.from(Object.entries(this)).forEach(([key, _]) => {
            const builderFlagKey = key as keyof BuilderFlags;
            const shouldReportKeyNotFound = !(builderFlagKey in data);
            const currentEnvironment = getCurrentEnvironment();

            Object.defineProperty(this, builderFlagKey, {
                get() {
                    if (shouldReportKeyNotFound) {
                        // Only report error in lower envs
                        if (currentEnvironment !== "prod") {
                            throw new Error(
                                `An attempt was made to populate a builder flag [key="${builderFlagKey}"] that was not provided by btJScriptGlobals or the global info API endpoint. Check to make sure the builder flag is marked serialized on the backend or remove the key from the builder flags object on the frontend.`
                            );
                        }
                        return true;
                    }
                    datadogRum.addFeatureFlagEvaluation(key, data[key]);
                    return data[key];
                },
            });

            // If there are any flags here that are not provided by the backend, throw an error in lower environments as they should be removed.
            if (shouldReportKeyNotFound && currentEnvironment !== "prod") {
                throw new Error(
                    `An attempt was made to populate a builder flag [key="${builderFlagKey}"] that was not provided by btJScriptGlobals or the global info API endpoint. Check to make sure the builder flag is marked serialized on the backend or remove the key from the builder flags object on the frontend.`
                );
            }
        });
    }

    invoiceCostCodeGrouping: boolean;
    automaticPaymentSplitting: boolean;
    btpProfileOnSettingsPage: boolean;
    dailyLogDetailsViewAllAttachmentsReact: boolean;
    unifiedAttachmentsExperienceScheduleItems: boolean;
    editBillPercentage: boolean;
    enableFileVersioning: boolean;
    enableNutrientAnnotations: boolean;
    jobProposalOwnerTemplates: boolean;
    leadToEstimateRerouteOption: boolean;
    massAttachmentsFromFiles2: boolean;
    massAttachmentsFromFiles3: boolean;
    showFileAssociations: boolean;
    singleAttachmentFromFileGroupC: boolean;
    syncBillEditstoAccounting: boolean;
    taxesChangeOrders: boolean;
    taxesEstimates: boolean;
    taxesSelectionChoices: boolean;
    templatesPreviewV1: boolean;
    builderAssistedPaymentsPhase1: boolean;
    costInboxFirstTimerEmserOpportunity: boolean;
    newHomeownerPermissionsSection: boolean;
    liteJoinFromSupplierCard: boolean;
    proposalDashboardOnLeads: boolean;
    proposalDashboardSavePDF: boolean;
    warrantyJobStatus: boolean;
    bdsLayoutToDosPanel: boolean;
    estimateConsolidationPermissions: boolean;
    clientUpdates: boolean;
    linkBillsToPOsOption1Wizard: boolean;
    applePay: boolean;
    googlePay: boolean;
    purchasingOneVisionIteration1: boolean;
    purchasingOneVisionIteration1_1: boolean;
    jcbBudgetAdjustments: boolean;
    showHomeownerManagementPage: boolean;
    fileAssociationsGroup1: boolean;
    fileAssociationsGroup2: boolean;
    workflowForDeletedOrDisabledSubs: boolean;
    notificationHistoryRedesign: boolean;
    accountingQuickLinkSubsCostCodes: boolean;
    jobProposalFlyout: boolean;
    relatedTagsOnHomeownerPortal: boolean;
    bTPaymentsAchCheckoutImprovements: boolean;
    removeRedundantHomeownerPermissions: boolean;
    bdsJobDetailsToFullPage: boolean;
    multipleIterativeProposals: boolean;
    jobDefaultsCompanySettingsPage: boolean;
    fileViewer: boolean;
    btpAllowPassCreditCardFeesToOwners: boolean;
    replaceFileWorkflowImprovements: boolean;
    simplifyJcbUpdates: boolean;
    sendToBudget: boolean;
    depositsNewShareModal: boolean;
    estimateMobileServices: boolean;
    rebatesSupplierEnrollmentACHRequired: boolean;
    jcbViewPermissionUpdate: boolean;
    moveClientPortalSettingsToTheClientPortalPreview: boolean;
    rebatesDriveExistingUsersToACHSetup: boolean;
    jcbToolTipsReimagined: boolean;
    automaticallySetContractPrice: boolean;
    oprImprovementsRelease1: boolean;
    fullPageCheckout: boolean;
    profitInsights: boolean;
    bdsJobDetailsBdsForm: boolean;
}

export class AppDefaultInfo {
    constructor(data: any | undefined) {
        // If data is undefined, assume that we are in the test environment
        // The default flag values are assumed
        if (data === undefined) {
            return;
        }

        Array.from(Object.entries(this)).forEach(([key, _]) => {
            const appDefaultKey = key as keyof AppDefaultInfo;
            const shouldReportKeyNotFound =
                !(appDefaultKey in data) && !appDefaultKey.startsWith("sentry");

            Object.defineProperty(this, appDefaultKey, {
                get() {
                    if (shouldReportKeyNotFound) {
                        console.error(
                            `An attempt was made to access an App Default Value [key="${appDefaultKey}"] that was not provided by the btJScriptGlobals or the global info API endpoint. Check to make sure the App Default property is marked serialized on the backend or remove the key from the AppDefaultValues object on the frontend.`
                        );
                        return true;
                    }

                    return data[key];
                },
            });

            // Don't fail during construction otherwise the entire solution would fail, but we do
            // want to throw errors in case a developer forgot to remove the key from this type
            if (shouldReportKeyNotFound) {
                console.error(
                    `An attempt was made to populate an App Default Value [key="${appDefaultKey}"] that was not provided by the btJScriptGlobals or the global info API endpoint. Check to make sure the App Default property is marked serialized on the backend or remove the key from the AppDefaultValues object on the frontend.`
                );
            }
        });
    }

    sentryReactDSN: string | undefined;
    importUIRefreshFrequency: number;
    sessionTimeoutMinutes: number;
    sentryReactSamplingRate: string;
    invisibleRecaptchaApiKey: string;
    enableCaptchaForSignups: boolean;
    mixpanelAnalyticsProjectId: string | undefined;
    enableIntercom: boolean;
    enableGoogleMaps: boolean;
    enableGTMSignups: boolean;
    enable3DSecureForPayments: boolean;
    dotNetSentryURL: string;
    reactSentryURL: string;
    dataDogRUM: boolean;
    dataDogApplicationId: string | undefined;
    dataDogClientToken: string | undefined;
    dataDogSite: string | undefined;
    dataDogSessionSampleRate: number;
    dataDogPremiumSampleRate: number;
    dataDogTrackUserInteractions: boolean;
    dataDogEnableTracing: boolean;
    sawmillIngestionEndpoint: string | undefined;
    sawmillFrontendBatchThreshold: number | undefined;
    sawmillFrontendMaxCachedEvents: number | undefined;
    sawmillFrontendMaxPayloadSizeBytes: number | undefined;
    enableSawmillFrontend: boolean;
    enableChurnkey: boolean;
    enableParagon: boolean;
    enableQualtrics: boolean;
    qualtricsInterceptorUrl: string;
    qualtricsProjectId: string;
    unreadNotificationCountCacheTimeout: number;
    marketplaceConfiguration: MarketplaceConfiguration;
    chatPollingRateInMilliseconds: number = 5000;
    chatMainNavIndicatorPollingRateInSeconds: number = 60;
    internalUserActivationReminderIntervalInMinutes: number = 4320;
    internalUserActivationReminderMaxDaysInMinutes: number = 43200;
    subActivationReminderIntervalInMinutes: number = 4320;
    subActivationReminderMaxDaysInMinutes: number = 43200;
    enablePpsJS: boolean;
    ppsSandboxFrontEndBaseUrl: string;
    ppsFrontEndBaseUrl: string;
    decoupleBtProdFromPpsSand: boolean;
    enableLoginPageRefresh: boolean;
    enableChameleon: boolean;
    chameleonToken: string | undefined;
    oAuthStateParamChanges: boolean;
    gainsightSSO: boolean;
    hideOldBudgetDate: Date | undefined;
    buildertrendLearningAcademyURL: string;
    pspdfkitLicenseKey: string;
    learningAcademyConfig: LearningAcademyConfiguration;
    enableAdminMainReact: boolean;
    deployRefreshPromptWaitTime: number;
    deployRefreshRedirectWaitTime: number;
    removeCreateLinkDecision: boolean;
}

export class MarketplaceConfiguration {
    constructor(data: any) {
        this.auth0Domain = data.auth0Domain;
        this.auth0ClientId = data.auth0ClientId;
        this.auth0Audience = data.auth0Audience;
        this.paragonProjectId = data.paragonProjectId;
        this.hubSpotContactSyncWorkflowId = data.hubSpotContactSyncWorkflowId;
        this.paragonSdkLocation = data.paragonSdkLocation;
    }

    auth0Domain: string;
    auth0ClientId: string;
    auth0Audience: string;
    paragonProjectId: string;
    hubSpotContactSyncWorkflowId: string;
    paragonSdkLocation: string;
}

export class SupportContactInfo {
    constructor(data: any) {
        this.email = data.email;
        this.header = data.header;
        this.phoneNumber = data.phoneNumber;
        this.workDays = data.workDays;
        this.workHours = data.workHours;
        this.timezone = data.timezone;
    }

    email: string;
    header: string;
    phoneNumber: string;
    workDays: string;
    workHours: string;
    timezone: string;
}

const defaultGoogleMapsApiKey = "AIzaSyCr7G84uWpgEqpZTwgLDO3riPtOQaKITqA";
export const btJScriptGlobalsDefault = {
    loginTypeInt: BTLoginTypes.BUILDER,
    loginType: "BUILDER",
    getBuilderId: 1,
    getBuilderNumberDecimalSeparator: CurrencyLocale.default.decimalSeparator,
    getBuilderNumberGroupSeparator: CurrencyLocale.default.groupSeparator,
    getBuilderCurrencySymbol: CurrencyLocale.default.currencySymbol,
    uses24HourClock: false,
    isProduction: false,
    isDemoAccount: true,
    orgLinkIds: new Set<number>(),
    builderTimeFormatForMoment: "h:mm a",
    userDisplayName: "User",
    userId: "userId",
    builderShortDateFormatForMoment: "M-D-YYYY",
    builderHeaderColor: Blue9.replace("#", ""),
    builderName: "Builder Name",
    googleMapsApiKey: defaultGoogleMapsApiKey,
    googleMapsApiSrc: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=visualization,places&key=${defaultGoogleMapsApiKey}&callback=btMaster.placesAndMapsApiLoaded`,
    builderInformation: {
        scheduleDefaultTab: 1,
    },
    supportContactInfo: {
        header: "Buildertrend Support",
        phoneNumber: "+1 (888) 415-7149",
        workDays: "Mon - Fri",
        workHours: "8:00am - 5:00pm",
        timezone: "CST (GMT-5)",
        email: "support@buildertrend.com",
    },
    taxesOptOut: false,
    isInTaxesSupportedRegion: true,
};

interface ILearningAcademyConfiguration {
    leads: string;
    schedule: string;
    toDos: string;
    dailyLogs: string;
    changeOrders: string;
    warranty: string;
    selections: string;
    timeClock: string;
    media: string;
    communications: string;
    estimates: string;
    bids: string;
    bills: string;
    purchaseOrders: string;
    budget: string;
    ownerInvoices: string;
    costCodes: string;
    projectManagement: string;
    takeOff: string;
}

export class LearningAcademyConfiguration implements ILearningAcademyConfiguration {
    constructor(data: ILearningAcademyConfiguration) {
        this.leads = data.leads;
        this.schedule = data.schedule;
        this.toDos = data.toDos;
        this.dailyLogs = data.dailyLogs;
        this.changeOrders = data.changeOrders;
        this.warranty = data.warranty;
        this.selections = data.selections;
        this.timeClock = data.timeClock;
        this.media = data.media;
        this.communications = data.communications;
        this.estimates = data.estimates;
        this.bids = data.bids;
        this.bills = data.bills;
        this.purchaseOrders = data.purchaseOrders;
        this.budget = data.budget;
        this.ownerInvoices = data.ownerInvoices;
        this.costCodes = data.costCodes;
        this.projectManagement = data.projectManagement;
        this.takeOff = data.takeOff;
    }

    leads: string;
    schedule: string;
    toDos: string;
    dailyLogs: string;
    changeOrders: string;
    warranty: string;
    selections: string;
    timeClock: string;
    media: string;
    communications: string;
    estimates: string;
    bids: string;
    bills: string;
    purchaseOrders: string;
    budget: string;
    ownerInvoices: string;
    costCodes: string;
    projectManagement: string;
    takeOff: string;
}
