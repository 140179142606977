import moment, { Moment } from "moment";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { AccountingIntegrationType, AccountingMethod } from "types/enum";

import { getSelectedValue } from "utilities/form/form";

import { IEntityExtraData } from "commonComponents/entity/accounting/AccountingLinkingOptions/AccountingLinkingOptions.api.types";
import {
    JobsiteLinkingTypes,
    TaxOptions,
} from "commonComponents/financial/Common/Accounting.types";

import type { default as AccountingSettingsConnectedAPIResponseExample } from "./AccountingSettingsConnected.api.json";
import type { default as QbdEntityThresholdResponseExample } from "./QBDEntityThreshold.api.json";
import type { default as SyncAccountingMethodResponseExample } from "./SyncAccountingMethod.api.json";

type QBDListType = (typeof AccountingSettingsConnectedAPIResponseExample.qbdIdTypes)[0];
type QbdEntityThresholdAPIResponse = typeof QbdEntityThresholdResponseExample;
type SyncAccountingMethodAPIResponse = typeof SyncAccountingMethodResponseExample;

export type AccountingSettingsFormActions =
    | undefined
    | "save"
    | "cancel"
    | "disconnect"
    | "cancelUpgrade"
    | "checkAccountingData"
    | "clearAccountingData"
    | "clearPendingSyncItems"
    | "sync"
    | "webConnectorDialog"
    | "qbSyncSchedule"
    | "saveInitialTax"
    | "syncAccountingMethod";

export const QuickBooksImageLink = "/images/Marketplace/quickBooksFg.svg";
export const XeroImageLink = "/images/Marketplace/xeroBlue.png";

export enum WebConnectorState {
    Hidden,
    Install,
    Configure,
}

export enum AccountingView {
    SelectIntegration,
    WhatsIncluded,
    PartiallyConnected,
    BackToBuilderSettings,
}

export const getIntegrationSellingPoints = (
    integrationType: AccountingIntegrationType
): string[] => {
    const buildertrendCompanyName = "Buildertrend";
    const jobConstant = "jobs";
    const subConstant = "subs/vendors";
    switch (integrationType) {
        case AccountingIntegrationType.QuickBooksDesktop:
        case AccountingIntegrationType.QuickBooksOnline:
            return [
                "Use the same workflow that you have been used to and see it all in Buildertrend!",
                "Have access to an online, cloud-based version of your job costs wherever you need it.",
                "Our QuickBooks experts can help you get up and running fast.",
            ];
        case AccountingIntegrationType.Xero:
            return [
                "Connect to your organization and eliminate duplicate entry.",
                "Import your Xero inventory items.",
                `Sync ${buildertrendCompanyName} ${subConstant} and ${jobConstant} to Xero contacts.`,
                "Sync owner invoices to Xero invoices.",
                "Create bills from purchase order and bill payments.",
            ];
        default:
            return [];
    }
};

export interface IAccountingSettingsFormValues {
    accountingJobsiteLinkingType: JobsiteLinkingTypes;
    createNewAccountingCustomer: boolean;
    createNewAccountingVendor: boolean;
    createTimeActivity: boolean;
    includeAccountingCostsOnBudget: boolean;
    createNewAccountingInvoiceCustomerInvoiceReleased: boolean;
    createNewAccountingMemoCreditMemoReleased: boolean;
    createNewAccountingCheckFromPO: boolean;
    defaultOptionToSendBillToAccounting: boolean;
    accountingInvoiceAccount: string;
    changeOrderTaxOptions: string;
    taxOption: string;
    qbdCompanyFilePath: string;
    markAccountingBillAsBillable: boolean;
    accountingAccountsReceivableAccounts: string;
    canSaveMerchantAccount: boolean;
    merchantFeesAccount: string;
    merchantBankAccount: string;
    purchaseOrderOnlineFeeAccount: string;
    accountingAccountsPayableAccounts: string;
    timeClockPayrollItems: string;
    accountingInvoiceAccounts: string;
    purchaseOrderTaxOptions: string;
    individualOwnerPaymentsTax: boolean;
    singleOwnerPaymentsTax: boolean;
    xeroOrganizationId: string;
    qbdIdTypes: number[];
    accountingMethod: AccountingMethod | null;
    allowBillEditSyncs: boolean;
}

export class AccountingListItem {
    constructor(data: any) {
        this.canSelect = data.canSelect;
        this.costCodes = data.costCodes
            ? data.costCodes.map((cc: any) => new BTSelectItem(cc))
            : [];
        this.hasSalesTax = data.hasSalesTax;
        this.taxAssignmentError = data.taxAssignmentError;
    }

    canSelect: boolean;
    costCodes: BTSelectItem[];
    hasSalesTax?: boolean;
    taxAssignmentError?: string;
}

export class AccountingSetupDropdownData {
    constructor(data: any) {
        if (data && data.listItems) {
            this.currentIdIsNotInList = data.currentIdIsNotInList;
            const treeData = data.listItems.map(
                (li: any) =>
                    new BTSelectItem<AccountingListItem>({
                        title: li.Text,
                        value: li.Value,
                        selected: li.Selected,
                        extraData: {
                            costCodes: li.costCodes,
                            canSelect: li.canSelect,
                            hasSalesTax: li.hasSalesTax,
                            taxAssignmentError: li.taxAssignmentError,
                        },
                    })
            );

            this.treeData = [
                new BTSelectItem<AccountingListItem>({
                    title: "-- None selected --",
                    id: "",
                    value: "",
                    selected: getSelectedValue(treeData) === undefined,
                }),
            ];
            this.treeData = this.treeData.concat(treeData);
        } else {
            this.treeData = [];
        }
    }

    currentIdIsNotInList: boolean;
    treeData: BTSelectItem<AccountingListItem>[];
}

export class AccountingSetupTaxToolTipData {
    constructor(data: any) {
        this.invoiceBillVerbiage = data.invoiceBillVerbiage;
        this.displayTaxInclusiveExample = data.displayTaxInclusiveExample;
        this.taxRateLocation = data.taxRateLocation;
        this.integrationName = data.integrationName;
    }

    invoiceBillVerbiage: string;
    displayTaxInclusiveExample: boolean;
    taxRateLocation: string;
    integrationName: string;
}

export class AccountingSettingsData {
    constructor(data: any) {
        this.tokensMayBeSuspended = data.tokensMayBeSuspended;
        this.hideAccountingContent = data.hideAccountingContent;
        this.hasPurchaseOrders = data.hasPurchaseOrders;
        this.hasOwnerInvoices = data.hasOwnerInvoices;
        this.hasCreditMemos = data.hasCreditMemos;
        this.showXeroAttachmentsBanner = data.showXeroAttachmentsBanner;
        this.xeroAttachmentsBannerMessage = data.xeroAttachmentsBannerMessage;

        this.jobsiteLinkingTypes = data.jobsiteLinkingTypes
            ? data.jobsiteLinkingTypes.map(
                  (item: any) =>
                      new BTSelectItem<IEntityExtraData>({
                          title: item.Text,
                          value: item.Value,
                          selected: item.Selected,
                      })
              )
            : [];
        this.defaultTaxOptions = data.defaultTaxOptions
            ? data.defaultTaxOptions.map(
                  (cc: any) =>
                      new BTSelectItem({ title: cc.Text, value: cc.Value, selected: cc.Selected })
              )
            : [];
        this.taxOptions = data.taxOptions
            ? data.taxOptions.map(
                  (cc: any) =>
                      new BTSelectItem({ title: cc.Text, value: cc.Value, selected: cc.Selected })
              )
            : [];
        this.taxOptionsToolTipData = data.taxOptionsToolTipData
            ? new AccountingSetupTaxToolTipData(data.taxOptionsToolTipData)
            : undefined;

        // Possible on integration error for these response fields to be null, but the lists still need to be defined.
        this.invoiceAccounts = new AccountingSetupDropdownData(data.invoiceAccounts);

        this.receivableAccounts = data.receivableAccounts
            ? new AccountingSetupDropdownData(data.receivableAccounts)
            : undefined;
        this.payableAccounts = data.payableAccounts
            ? new AccountingSetupDropdownData(data.payableAccounts)
            : undefined;
        this.payableMerchantFeeExpenseAccounts = data.payableMerchantFeeExpenseAccounts
            ? new AccountingSetupDropdownData(data.payableMerchantFeeExpenseAccounts)
            : undefined;
        this.receivableMerchantFeeExpenseAccounts = data.receivableMerchantFeeExpenseAccounts
            ? new AccountingSetupDropdownData(data.receivableMerchantFeeExpenseAccounts)
            : undefined;
        this.bankAccounts = data.bankAccounts
            ? new AccountingSetupDropdownData(data.bankAccounts)
            : undefined;
        this.timeClockPayrollItems = data.timeClockPayrollItems
            ? new AccountingSetupDropdownData(data.timeClockPayrollItems)
            : undefined;
        this.defaultTaxOptionForNewSignup = data.defaultTaxOptionForNewSignup;

        this.accountingIntegrationTypeName = data.accountingIntegrationTypeName;
        this.accountingIntegrationType = data.accountingIntegrationType;
        this.accountingSystemShortName = data.accountingSystemShortName;
        this.usePayrollItems = data.usePayrollItems;
        this.mainStyle = data.mainStyle;
        this.accountingHelpLink = data.accountingHelpLink;
        this.useIndividualTaxOptions = data.useIndividualTaxOptions;
        this.isAdmin = data.isAdmin;
        this.displaySyncPendingSyncTransactions = data.displaySyncPendingSyncTransactions;
        this.displayAccountingInvoiceOptionsHeader = data.displayAccountingInvoiceOptionsHeader;
        this.showOwnerTaxOwnerPayments = data.showOwnerTaxOwnerPayments;
        this.showOwnerTaxCreditMemos = data.showOwnerTaxCreditMemos;
        this.isConnected = data.isConnected;
        this.isReconnecting = data.isReconnecting;
        this.hasValidToken = data.hasValidToken;
        this.displayPartiallyConnected = data.displayPartiallyConnected;
        this.displayReadyToConnect = data.displayReadyToConnect;
        this.displayReadyToConnectForUser = data.displayReadyToConnectForUser;
        this.displayConnected = data.displayConnected;
        this.displayWebConnectorPanel = data.displayWebConnectorPanel;
        this.connectedToMerchant = data.connectedToMerchant;
        this.merchantNotSetup = data.merchantNotSetup;
        this.displayBuilderPayingSubFees = data.displayBuilderPayingSubFees;
        this.userCanViewSetup = data.userCanViewSetup;
        this.allowBillableStatus = data.allowBillableStatus;
        this.isTestAccount = data.isTestAccount;
        this.autoCreateNewAccountingCustomer = data.autoCreateNewAccountingCustomer;
        this.createNewAccountingVendor = data.createNewAccountingVendor;
        this.createNewAccountingCheckFromPO = data.createNewAccountingCheckFromPO;
        this.markAccountingBillAsBillable = data.markAccountingBillAsBillable;
        this.createNewAccountingInvoiceCustomerInvoiceReleased =
            data.createNewAccountingInvoiceCustomerInvoiceReleased;
        this.createNewAccountingMemoCreditMemoReleased =
            data.createNewAccountingMemoCreditMemoReleased;
        this.defaultOptionToSendBillToAccounting = data.defaultOptionToSendBillToAccounting;
        this.createTimeActivity = data.createTimeActivity;
        this.includeAccountingCosts = data.includeAccountingCosts;
        this.allowJobsUnderCustomer = data.allowJobsUnderCustomer;
        this.supportsTimeCardIntegration = data.supportsTimeCardIntegration;
        this.supportsAccountingCostsOnBudget = data.supportsAccountingCostsOnBudget;
        this.allowsPendingSyncState = data.allowsPendingSyncState;
        this.canSeeQuickSyncSchedule = data.canSeeQuickSyncSchedule;
        this.webConnectorSessionStartDate =
            data.webConnectorSessionStartDate && moment(data.webConnectorSessionStartDate.value);
        this.companyFilePath = data.companyFilePath;
        this.previousCompanyName = data.previousCompanyName;
        this.previousCompanyId = data.previousCompanyId;
        this.accountingInvoiceAccountLabel = data.accountingInvoiceAccountLabel;
        this.depositAccountTerm = data.depositAccountTerm;
        this.depositOfflineAccountTerm = data.depositOfflineAccountTerm;
        this.receivableAccountingTerm = data.receivableAccountingTerm;
        this.accountingTermForBill = data.accountingTermForBill;
        this.accountingNameForVendor = data.accountingNameForVendor;
        this.accountingTermForInvoice = data.accountingTermForInvoice;
        this.accountingTermForCreditMemo = data.accountingTermForCreditMemo;
        this.accountingTermForAccountsPayableAccount = data.accountingTermForAccountsPayableAccount;
        this.accountingTermForSecondaryJobEntity = data.accountingTermForSecondaryJobEntity;
        this.accountingTermForPrimaryJobEntity = data.accountingTermForPrimaryJobEntity;
        this.autoCreateNewString = data.autoCreateNewString;
        this.clearingAccountForBillPOToolTipMessage = data.clearingAccountForBillPOToolTipMessage;
        this.accountsReceivableToolTipMessage = data.accountsReceivableToolTipMessage;
        this.setupBankAccountsToolTipMessage = data.setupBankAccountsToolTipMessage;
        this.expenseAccountToolTipMessage = data.expenseAccountToolTipMessage;
        this.allowCreateBillPayment = data.allowCreateBillPayment;
        this.accountingSetupInvoiceItemTooltipMessage =
            data.accountingSetupInvoiceItemTooltipMessage;
        this.continueUpgradeImageSrc = data.continueUpgradeImageSrc;
        this.upgradeImageIcon = data.upgradeImageIcon;
        this.upgradeImage = data.upgradeImage;
        this.accountingCostsOnBudgetCheckBoxMessage = data.accountingCostsOnBudgetCheckBoxMessage;
        this.accountingCostsOnBudgetToolTipMessage = data.accountingCostsOnBudgetToolTipMessage;
        this.builderConfirmAccountingTerms = data.builderConfirmAccountingTerms
            ? new BuilderConfirmAccountingTermsLoadResponse(data.builderConfirmAccountingTerms)
            : undefined;
        this.webConnectorConfigurationData = data.webConnectorConfigurationData;
        this.accountingMethod = data.accountingMethod;
        this.qbdIdTypes = data.qbdIdTypes
            ? data.qbdIdTypes.map(
                  (cc: QBDListType) =>
                      new BTSelectItem({ title: cc.Text, value: cc.Value, selected: cc.Selected })
              )
            : [];
        this.allowBillEditSyncs = data.allowBillEditSyncs;
    }

    tokensMayBeSuspended: boolean;
    hideAccountingContent: boolean;
    hasPurchaseOrders: boolean;
    hasOwnerInvoices: boolean;
    hasCreditMemos: boolean;
    showXeroAttachmentsBanner: boolean;
    xeroAttachmentsBannerMessage: string;

    jobsiteLinkingTypes: BTSelectItem<IEntityExtraData>[];
    defaultTaxOptions: BTSelectItem[];
    taxOptions: BTSelectItem[];
    taxOptionsToolTipData: AccountingSetupTaxToolTipData | undefined;
    invoiceAccounts: AccountingSetupDropdownData | undefined;
    receivableAccounts: AccountingSetupDropdownData | undefined;
    payableAccounts: AccountingSetupDropdownData | undefined;
    payableMerchantFeeExpenseAccounts: AccountingSetupDropdownData | undefined;
    receivableMerchantFeeExpenseAccounts: AccountingSetupDropdownData | undefined;
    bankAccounts: AccountingSetupDropdownData | undefined;
    timeClockPayrollItems: AccountingSetupDropdownData | undefined;
    defaultTaxOptionForNewSignup?: TaxOptions;

    accountingIntegrationTypeName: string;
    accountingIntegrationType: AccountingIntegrationType;
    accountingSystemShortName: string;
    usePayrollItems: boolean;
    mainStyle: string;
    accountingHelpLink: string;
    useIndividualTaxOptions: boolean;
    isAdmin: boolean;
    displaySyncPendingSyncTransactions: boolean;
    displayAccountingInvoiceOptionsHeader: boolean;
    showOwnerTaxOwnerPayments: boolean;
    showOwnerTaxCreditMemos: boolean;
    isConnected: boolean;
    isReconnecting: boolean;
    hasValidToken: boolean;
    displayPartiallyConnected: boolean;
    displayReadyToConnect: boolean;
    displayReadyToConnectForUser: boolean;
    displayConnected: boolean;
    displayWebConnectorPanel: boolean;
    connectedToMerchant: boolean;
    merchantNotSetup: boolean;
    displayBuilderPayingSubFees: boolean;
    userCanViewSetup: boolean;
    allowBillableStatus: boolean;
    isTestAccount: boolean;
    autoCreateNewAccountingCustomer: boolean;
    createNewAccountingVendor: boolean;
    createNewAccountingCheckFromPO: boolean;
    markAccountingBillAsBillable: boolean;
    createNewAccountingInvoiceCustomerInvoiceReleased: boolean;
    createNewAccountingMemoCreditMemoReleased: boolean;
    defaultOptionToSendBillToAccounting: boolean;
    createTimeActivity: boolean;
    includeAccountingCosts: boolean;
    allowJobsUnderCustomer: boolean;
    supportsTimeCardIntegration: boolean;
    supportsAccountingCostsOnBudget: boolean;
    allowsPendingSyncState: boolean;
    canSeeQuickSyncSchedule: boolean;
    webConnectorSessionStartDate: moment.Moment;
    companyFilePath: string;
    previousCompanyName: string | null;
    previousCompanyId: string | null;
    accountingInvoiceAccountLabel: string;
    depositAccountTerm: string;
    depositOfflineAccountTerm: string;
    receivableAccountingTerm: string;
    accountingTermForBill: string;
    accountingNameForVendor: string;
    accountingTermForInvoice: string;
    accountingTermForCreditMemo: string;
    accountingTermForAccountsPayableAccount: string;
    accountingTermForSecondaryJobEntity: string;
    accountingTermForPrimaryJobEntity: string;
    autoCreateNewString: string;
    clearingAccountForBillPOToolTipMessage: string;
    accountsReceivableToolTipMessage: string;
    setupBankAccountsToolTipMessage: string;
    expenseAccountToolTipMessage: string;
    allowCreateBillPayment: string;
    accountingSetupInvoiceItemTooltipMessage: string;
    continueUpgradeImageSrc: string;
    upgradeImageIcon: string;
    upgradeImage: string;
    accountingCostsOnBudgetCheckBoxMessage: string;
    accountingCostsOnBudgetToolTipMessage: string;
    builderConfirmAccountingTerms?: BuilderConfirmAccountingTermsLoadResponse;
    webConnectorConfigurationData: string;

    accountingMethod?: AccountingMethod;
    qbdIdTypes: BTSelectItem[];
    allowBillEditSyncs: boolean;
}

export class BuilderConfirmAccountingTermsLoadResponse {
    constructor(data: any) {
        this.integrationName = data.integrationName;
        this.integrationType = data.integrationType;
        this.isTestAccount = data.isTestAccount;
        this.profileUpgradedText = data.profileUpgradedText;
        this.isProrated = data.isProrated;
        this.updatedBuilderCost = data.updatedBuilderCost;
        this.billingFrequencyText = data.billingFrequencyText;
        this.currentBuilderFee = data.currentBuilderFee;
        this.proratedAccountingFee = data.proratedAccountingFee;
        this.proratedTax = data.proratedTax;
        this.upgradeImageSource = data.upgradeImageSource;
        this.hasPaidForAccountingThisMonth = data.hasPaidForAccountingThisMonth;
        this.packageIncludesAccounting = data.packageIncludesAccounting;
        this.freeAccountingIntegration = data.freeAccountingIntegration;
        this.buildersAccountingPaymentFailed = data.buildersAccountingPaymentFailed;
    }

    integrationName: string;
    integrationType: AccountingIntegrationType;
    isTestAccount: boolean;
    profileUpgradedText: string;
    isProrated: boolean;
    updatedBuilderCost: number;
    billingFrequencyText: string;
    currentBuilderFee: number;
    proratedAccountingFee?: number;
    proratedTax?: number;
    upgradeImageSource: string;
    hasPaidForAccountingThisMonth: boolean;
    packageIncludesAccounting: boolean;
    freeAccountingIntegration: boolean;
    buildersAccountingPaymentFailed: boolean;
}

export class AccountingUrlResponse {
    constructor(data: any) {
        this.redirectUrl = data.redirectUrl;
    }

    redirectUrl: string;
}

export class OAuthMessage {
    constructor(integrationType: AccountingIntegrationType, data: any) {
        this.code = data.code;
        this.realmId = data.realmId;
        this.error = data.error;
        this.state = data.state;
        this.integrationType = integrationType;
    }

    integrationType: AccountingIntegrationType;
    // Shared Accounting Props
    code: string;
    // QBO Props
    realmId?: string;
    error?: string;
    // Xero Props
    state?: string;
}

export interface IFinalizeConnectionToAccountingRequest {
    integrationType: AccountingIntegrationType;
    skipSalesTax: boolean;
    oAuthData?: OAuthData;
}

export class OAuthData {
    constructor(data: any) {
        this.passedRequestToken = data.realmId; // will be undefined if Xero
        this.oAuthVerifier = data.oAuthVerifier;
        if (data.token) {
            this.xeroAccountingToken = new XeroAccountingToken(data.token);
        }
        this.authorizationCode = data.code;
    }

    passedRequestToken?: string;
    /** Quickbooks OAuth2 doesn't use token, and on the server token is of type object.
     * This xeroToken is typed as XeroAccountingToken on the server, which avoids the api
     * converting the regular token into a JObject.
     */
    xeroAccountingToken: XeroAccountingToken;
    oAuthVerifier: string;
    authorizationCode: string;
}

export class XeroAccountingToken {
    constructor(data: any) {
        this.accessToken = data.accessToken;
        this.refreshToken = data.refreshToken;
        this.tenantId = data.tenantId;
        this.xero_UserId = data.xero_UserId;
        this.xeroTenants = data.xeroTenants
            ? data.xeroTenants.map((tenant: any) => new XeroTenant(tenant))
            : undefined;
    }

    accessToken: string;
    refreshToken: string;
    tenantId: string;
    xero_UserId: string;
    xeroTenants?: XeroTenant[];
}

export interface IAccountingSubmitInitialTaxOptionsRequest {
    changeOrderTaxOptions: string | null;
    purchaseOrderTaxOptions: string | null;
    taxOptions: string | null;
}

export class AccountingSubmitInitialTaxOptionsResponse {
    constructor(data: any) {
        this.changeOrderTaxOptions = data.changeOrderTaxOptions;
        this.purchaseOrderTaxOptions = data.purchaseOrderTaxOptions;
        this.taxOptions = data.taxOptions;
    }
    changeOrderTaxOptions: string | null;
    purchaseOrderTaxOptions: string | null;
    taxOptions: string | null;
}

export interface ILoginBuilderByIntuitRequest {
    queryString: string;
    isDisconnect: boolean;
}

export class LoginBuilderByIntuitUrlResponse {
    constructor(data: any) {
        this.redirectUrl = data.redirectUrl;
    }

    redirectUrl: string;
}

export class QBSyncResponse {
    constructor(data: any) {
        this.lastRunDate = data.lastRunDate && moment(data.lastRunDate);
        this.lastRetrievedDateTime =
            data.lastRetrievedDateTime && moment(data.lastRetrievedDateTime);
        this.shouldRunImmediately = data.shouldRunImmediately;
        this.nextRunTime = data.nextRunTime && moment(data.nextRunTime);
    }

    lastRunDate?: Moment;
    lastRetrievedDateTime?: Moment;
    shouldRunImmediately: boolean;
    nextRunTime?: Moment;
}

export class QbdEntityThresholdResponse {
    constructor(data: QbdEntityThresholdAPIResponse) {
        this.isPassedEntityThreshold = data.isPassedEntityThreshold;
        this.isTimeout = data.isTimeout;
    }

    isPassedEntityThreshold: boolean;
    isTimeout: boolean;
}

export class XeroTenant {
    constructor(data: any) {
        this.tenantId = data.tenantId;
        this.tenantName = data.tenantName;
    }

    tenantId: string;
    tenantName: string;
}

export class SyncAccountingMethodResponse {
    constructor(data: SyncAccountingMethodAPIResponse) {
        this.isAccountingMethodUpdated = data.isAccountingMethodUpdated;
    }

    isAccountingMethodUpdated: boolean;
}
