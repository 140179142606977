import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import { useCallback, useContext, useMemo } from "react";

import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { BTLoginTypes, MediaType } from "types/enum";

import { canViewBTFileSystemInLightbox } from "commonComponents/btWrappers/BTLightbox/BTLightbox.utility";
import { BTTable, IBTColumnProps } from "commonComponents/btWrappers/BTTable/BTTable";
import {
    HiddenMediaContextMenu,
    MediaContextMenu,
} from "commonComponents/entity/media/common/MediaContextMenu/MediaContextMenu";
import {
    areContextItemsPresent,
    getAttachmentOverlayItems,
} from "commonComponents/entity/media/common/MediaContextMenu/MediaContextMenu.utility";
import { AttachmentOverlay } from "commonComponents/entity/media/common/MediaContextMenu/Overlays/AttachmentOverlay";
import {
    addedByColumn,
    lastModifiedColumn,
    permissionsColumn,
    thumbnailColumn,
    titleColumn,
} from "commonComponents/entity/media/common/MediaTable/MediaTableColumns";
import { SharingSettingsAPI } from "commonComponents/entity/sharing/SharingContainer/SharingContainer.types";

import { AnnotationType } from "entity/media/common/mediaTypes";

interface IMediaTableProps {
    files: BTFileSystem[];
    onOpenLightbox: (id: number) => void;
    onVideoDownload: (file: BTFileSystem) => Promise<void>;
    onAddClicked?: () => void;
    onViewPermissionsUpdate?: (file: BTFileSystem, loginType: BTLoginTypes) => Promise<void>;
    onNotify?: (file: BTFileSystem, loginType: BTLoginTypes) => Promise<void>;
    onFileAnnotate?: (
        docInstanceId: number,
        annotationType: AnnotationType,
        mediaType: MediaType
    ) => Promise<void>;
    onFileDraw?: (
        docInstanceId: number,
        annotationType: AnnotationType,
        mediaType: MediaType
    ) => Promise<void>;
    onEditOnline?: (docInstanceId: number, mediaType: MediaType) => Promise<void>;
    onFileDelete?: (file: BTFileSystem) => Promise<void>;
    isNewEntity?: boolean;
    isReadOnly?: boolean;
    sharingSettings?: SharingSettingsAPI;
}

export const MediaTable = ({
    files,
    isNewEntity,
    onVideoDownload,
    onViewPermissionsUpdate,
    onNotify,
    onFileAnnotate,
    onFileDraw,
    onEditOnline,
    onFileDelete,
    isReadOnly,
    sharingSettings,
    onOpenLightbox,
}: IMediaTableProps) => {
    const userInfo = useContext(UserInfoContext);

    const handleClickThumbnail = useCallback(
        (e: React.MouseEvent<HTMLImageElement, MouseEvent>, file: BTFileSystem) => {
            // stop row from being selected
            e.stopPropagation();
            // open lightbox on double click
            const canViewInLightbox = canViewBTFileSystemInLightbox(file);
            if (canViewInLightbox && e.detail === 2) {
                onOpenLightbox(file.id);
            }
        },
        [onOpenLightbox]
    );

    const fileColumns: IBTColumnProps<BTFileSystem>[] = useMemo(() => {
        const columns: IBTColumnProps<BTFileSystem>[] = [
            thumbnailColumn({ onClick: handleClickThumbnail, width: "8%" }),
            titleColumn(),
        ];
        if (userInfo?.loginType === BTLoginTypes.BUILDER) {
            columns.push(permissionsColumn({ width: "12%" }));
        }
        columns.push(addedByColumn({ width: "15%" }), lastModifiedColumn({ width: "20%" }), {
            width: "6%",
            dataIndex: "",
            title: "",
            render: (_, file) => {
                const items = getAttachmentOverlayItems(
                    file,
                    !!isReadOnly,
                    userInfo?.loginType,
                    sharingSettings,
                    !onNotify,
                    !onFileDraw,
                    !onFileAnnotate,
                    !onEditOnline
                );
                const hasContextItems = areContextItemsPresent(items);
                if (!hasContextItems) {
                    return <HiddenMediaContextMenu />;
                }
                return (
                    <MediaContextMenu
                        renderOverlay={(onMenuClick) => (
                            <AttachmentOverlay
                                items={items}
                                file={file}
                                isReadOnly={isReadOnly}
                                isNewEntity={isNewEntity}
                                onClickDownloadVideo={onVideoDownload}
                                onFilePermissionsUpdate={onViewPermissionsUpdate}
                                onClickNotify={onNotify}
                                onClickAnnotate={onFileAnnotate}
                                onClickDraw={onFileDraw}
                                onClickEditOnline={onEditOnline}
                                onClickDelete={onFileDelete}
                                onMenuClick={onMenuClick}
                            />
                        )}
                        file={file}
                    />
                );
            },
        });
        return columns;
    }, [
        handleClickThumbnail,
        userInfo?.loginType,
        isReadOnly,
        sharingSettings,
        onNotify,
        onFileDraw,
        onFileAnnotate,
        onEditOnline,
        isNewEntity,
        onVideoDownload,
        onViewPermissionsUpdate,
        onFileDelete,
    ]);

    const getFileRow = useCallback(
        ({ children, ...wrapperProps }: React.HTMLAttributes<HTMLTableRowElement>) => {
            const file = children![0].props.record;

            const items = getAttachmentOverlayItems(
                file,
                !!isReadOnly,
                userInfo?.loginType,
                sharingSettings,
                !onNotify,
                !onFileDraw,
                !onFileAnnotate,
                !onEditOnline
            );

            return (
                <MediaContextMenu
                    renderOverlay={(onMenuClick) => (
                        <AttachmentOverlay
                            items={items}
                            file={file}
                            isReadOnly={isReadOnly}
                            isNewEntity={isNewEntity}
                            onClickDownloadVideo={onVideoDownload}
                            onFilePermissionsUpdate={onViewPermissionsUpdate}
                            onClickNotify={onNotify}
                            onClickAnnotate={onFileAnnotate}
                            onClickDraw={onFileDraw}
                            onClickEditOnline={onEditOnline}
                            onClickDelete={onFileDelete}
                            onMenuClick={onMenuClick}
                        />
                    )}
                    file={file}
                >
                    <tr {...wrapperProps}>{children}</tr>
                </MediaContextMenu>
            );
        },
        [
            isReadOnly,
            userInfo?.loginType,
            sharingSettings,
            isNewEntity,
            onVideoDownload,
            onViewPermissionsUpdate,
            onNotify,
            onFileAnnotate,
            onFileDraw,
            onEditOnline,
            onFileDelete,
        ]
    );
    return (
        <BTTable<BTFileSystem>
            className="MediaTable"
            rowKey={(row) => row.id}
            rowClassName="FileRow"
            dataSource={files}
            columns={fileColumns}
            sticky={{ topLevel: 200 }}
            components={{
                body: {
                    row: getFileRow,
                },
            }}
            pagination={false}
            size="small"
            emptyText="No attachments"
        />
    );
};
