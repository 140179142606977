import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { BTSelectItem, JobInfo } from "types/apiResponse/apiResponse";
import { BTLoginTypes, DocumentInstanceType, MediaType, TempFileTypes } from "types/enum";

import { isNullOrUndefined } from "utilities/object/object";

import { ISelectedFile } from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer.types";
import { DiscussionsEntity } from "commonComponents/entity/discussion/DiscussionContainer/DiscussionContainer.api.types";
import { IMediaPropertiesEntity } from "commonComponents/entity/media/MediaProperties/MediaProperties.api.types";
import {
    ShareNotifyAPI,
    SharingSettingsAPI,
} from "commonComponents/entity/sharing/SharingContainer/SharingContainer.types";

import type { default as ProductMetadataJson } from "entity/document/Document/Document.Metadata.api.json";
import {
    AnnotationInformation,
    AnnotationLayer,
    AttachedMedia,
    DocumentInstanceUpdateType,
    IFolderPermissionExtraData,
} from "entity/media/common/mediaTypes";

type ProductMetadataApiData = typeof ProductMetadataJson;
type ProductMetadataRowApiData = ProductMetadataApiData["metaDataList"][number];

export class DocumentEntity implements IMediaPropertiesEntity {
    constructor(data: any) {
        this.allowedFileTypes = ["*"];
        this.annotationInformation = data.documentListItem
            ? new AnnotationInformation(data.documentListItem)
            : undefined;
        this.annotationLayers = data.annotationLayers
            ? data.annotationLayers.map((layer: any) => new AnnotationLayer(layer))
            : undefined;
        this.builderId = data.builderId;
        this.instanceKey = uuidv4();
        this.canAdd = data.canAdd;
        this.canEdit = data.canEdit;
        this.canDelete = data.canDelete;
        this.description = !isNullOrUndefined(data.description) ? data.description.value : "";
        if (data.discussions) {
            this.discussions = new DiscussionsEntity(data.discussions);
        }
        this.docPath = data.docPath;
        this.downloadUrl = data.downloadUrl;
        this.isTemplate = data.isEditableByZoho;
        if (data.jobInfo) {
            this.jobInfo = new JobInfo(data.jobInfo);
            this.jobName = data.jobInfo.name;
        } else {
            this.jobName = data.jobName;
        }
        this.modifiedBy = !isNullOrUndefined(data.lastModifiedBy)
            ? data.lastModifiedBy.value
            : null;
        this.modifiedDate = !isNullOrUndefined(data.modifiedDate)
            ? moment(data.modifiedDate.value)
            : null;
        this.ownerName = data.ownerName;
        this.parentFolder = data.parentFolder.options[0].options.map(
            (folder: any) =>
                new BTSelectItem({
                    ...folder,
                    selected: Number(folder.id) === Number(data.parentFolder.value[0]),
                })
        );
        this.parentFolderId = data.parentFolder.value[0];
        this.selectedFile = data.file.selectedFile;
        this.selectedInternalUsers = data.showInternalUsers.value;
        this.selectedSubs = data.showSubs.value;
        this.showInternalUsers = data.showInternalUsers.options[0].options.map(
            (user: any) => new BTSelectItem(user)
        );
        this.showSubs = data.showSubs.options[0].options.map((sub: any) => new BTSelectItem(sub));
        this.title = !isNullOrUndefined(data.documentName) ? data.documentName.value : "";
        this.uploadedBy = data.uploadedBy;
        this.uploadedDate = !isNullOrUndefined(data.uploadedDate)
            ? moment(data.uploadedDate.value)
            : null;

        const selectedFolder = this.parentFolder.filter(
            (folder) => Number(folder.id) === Number(this.parentFolderId)
        )[0];

        this.allowSubs = this.selectedFile.showSubs;
        this.sharingSettings = new SharingSettingsAPI({
            internalUserPreference: new ShareNotifyAPI({
                canNotify: true,
                canShare: false,
                canUpdate: true,
                notify: false,
                share: false,
            }),
            ownerPreference: new ShareNotifyAPI({
                canNotify: true,
                canShare: false,
                canUpdate: selectedFolder.extraData!.canShowOwner,
                notify: false,
                share: false,
            }),
            subVendorPreference: new ShareNotifyAPI({
                canNotify: true,
                canShare: false,
                canUpdate: selectedFolder.extraData!.canShowSubs,
                notify: false,
                share: false,
            }),
            showShare: false,
        });
        this.showOwner = !isNullOrUndefined(data.showOwner)
            ? data.showOwner.value
            : selectedFolder.extraData!.canShowOwner;
        this.sizeInBytes = data.sizeInBytes;
        this.title = !isNullOrUndefined(data.documentName) ? data.documentName.value : "";
        this.uploadedBy = data.uploadedBy;
        this.uploadedDate = !isNullOrUndefined(data.uploadedDate)
            ? moment(data.uploadedDate.value)
            : null;
        this.extension = data.extension;
    }

    allowedFileTypes: string[];
    allowSubs: boolean;
    annotationInformation?: AnnotationInformation;
    annotationLayers?: AnnotationLayer[];
    builderId: number;
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
    description: string;
    discussions: DiscussionsEntity;
    docPath: string;
    downloadUrl: string;
    id: number;
    isTemplate?: boolean;
    jobId: number;
    jobName: string;
    jobInfo?: JobInfo;
    mediaType: MediaType;
    modifiedBy: string;
    modifiedDate: moment.Moment | null;
    ownerName: string;
    parentFolder: BTSelectItem<IFolderPermissionExtraData>[];
    parentFolderId: number;
    selectedFile: ISelectedFile;
    selectedInternalUsers: number[];
    selectedSubs: number[];
    sharingSettings: SharingSettingsAPI;
    showInternalUsers: BTSelectItem[];
    showOwner: boolean;
    showSubs: BTSelectItem[];
    sizeInBytes: number;
    title: string;
    uploadedBy: string;
    uploadedDate: moment.Moment | null;
    instanceKey: string;
    extension: string;
}

export enum TemplateType {
    BlankWord,
    BlankExcel,
    ExistingWord,
    ExistingExcel,
    ExistingPdf,
    None,
}

export class DocumentCreateResponse {
    constructor(data: any) {
        this.documentInstanceId = data.documentInstanceId;
    }

    documentInstanceId: number;
}

export class DocumentUpdateResponse {
    constructor(data: any) {
        this.linkedItems = data.linkedItems
            ? data.linkedItems.map((item: any) => new AttachedMedia(item))
            : null;
    }

    linkedItems: AttachedMedia[];
}

export interface IDocumentCreateRequest {
    description: string;
    documentName: string;
    isTemplate: boolean;
    jobId: number;
    notificationComments: string;
    notifyInternalUsers: boolean;
    notifyOwner: boolean;
    notifySubs: boolean;
    parentFolder: number;
    showInternalUsers: number[];
    showOwner: boolean;
    showSubs: number[];
    templateDocumentInstanceId: number;
    templateType: TemplateType;
}

export interface IDocumentGetDefaultsRequest {
    folderId: number;
    jobId: number;
    templateId: number;
    templateType: TemplateType;
}

export interface IDocumentUpdateRequest {
    checkDocumentVersioning: boolean;
    description: string;
    documentName: string;
    extension: string;
    isTemplate: boolean;
    parentFolder: number[]; // This SHOULD be a number, but the server has it as an array even though it always calls .First on it. Can't change it because it's used by mobile
    showInternalUsers: number[];
    showOwner: boolean;
    showSubs: number[];
    tempFileId: number;
    updateType: DocumentInstanceUpdateType;
}

export class FilesFromExternalUrlsResponse {
    constructor(data: any) {
        this.fileSystemList = data.fileSystemList.map((s: any) => new BTFileSystem(s));
    }
    fileSystemList: BTFileSystem[];
}

export interface IFilesFromExternalUrlsRequest {
    externalUrls: string[];
    jobId: number;
    tempFileType: TempFileTypes;
}

export class MetadataProductResponse {
    constructor(data: ProductMetadataApiData) {
        this.metaDataList = data.metaDataList.map((s: any) => new Metadata(s));
    }

    metaDataList: Metadata[];
}

export class Metadata {
    constructor(data: ProductMetadataRowApiData) {
        this.url = data.url;
        this.title = data.title;
        this.description = data.description;
        this.keywords = data.keywords;
        this.imageUrl = data.imageUrl;
        this.siteName = data.siteName;
    }

    url: string;
    title: string;
    description: string;
    keywords: string;
    imageUrl: string;
    siteName: string;
}

export class UpdateDocumentViewPermissionsRequest {
    documentInstanceId: number;
    type: BTLoginTypes;
    canView: boolean;
}

export class NotifyOnDocumentLinkPermissionChangeRequest {
    documentInstanceId: number;
    type: BTLoginTypes;
    documentType: DocumentInstanceType;
}
