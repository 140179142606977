import { message } from "antd";
import classNames from "classnames";
import { debounce } from "lodash-es";
import memoizeOne from "memoize-one";
import queryString from "query-string";
import { PureComponent, useContext } from "react";
import { Route, RouteComponentProps } from "react-router";

import { AppContext, AppProvider } from "helpers/AppProvider";
import { AppProviderHandler } from "helpers/AppProvider.api.handler";
import { AppDefaultInfo, BuilderInfo, EnvironmentInfo, UserInfo } from "helpers/AppProvider.types";
import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { EnvironmentInfoContext } from "helpers/globalContext/EnvironmentInfoContext";
import { SpaInfoContext } from "helpers/globalContext/SPAInfoContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { IAPIHandlerResult } from "types/apiResponse/apiResponse";
import { BTLocalStorage, LocalStorageKeys } from "types/btStorage";
import { CommonConstants } from "types/constants";
import { BTLoginTypes, JobStatusTypes, MenuItemName } from "types/enum";

import { track } from "utilities/analytics/analytics";
import { APIError, NoAccessError, showAPIErrorMessage } from "utilities/apiHandler";
import { getSelectedValue } from "utilities/form/form";
import {
    filterJobsList,
    getJobPickerState,
    getSelectedJobId,
    getSelectedJobIdsForJobPicker,
    isSpecialJob,
    setJobPickerState,
} from "utilities/jobPicker/jobPicker";
import { EmptyStateEntity } from "utilities/list/list.types";
import {
    getCurrentPortalType,
    getLoginTypeFromCurrentPortal,
    isInPortal,
    PortalType,
} from "utilities/portal/portal";
import { getBaseRoute, routes } from "utilities/routes";
import { routesWebforms } from "utilities/routesWebforms";

import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import {
    EmptyStateHandler,
    IEmptyStateHandler,
} from "commonComponents/entity/emptyState/common/EmptyState.api.handler";
import { RouteJob } from "commonComponents/entity/job/RouteJob/RouteJob";
import { JobStatusFilterTypes } from "commonComponents/entity/jobAccess/JobAccess/JobAccess.api.types";
import { mapJobStatusToJobStatusFilterTypes } from "commonComponents/entity/jobAccess/JobAccess/JobAccess.utils";
import { HotkeyDisplaySetup } from "commonComponents/helpers/HotkeyDisplaySetup/HotkeyDisplaySetup";
import { AcceptTermsAndPolicies } from "commonComponents/utilities/AcceptTermsAndPolicies/acceptTermsAndPolicies/AcceptTermsAndPolicies";
import { AcceptTermsAndPoliciesHandler } from "commonComponents/utilities/AcceptTermsAndPolicies/acceptTermsAndPolicies/AcceptTermsAndPolicies.api.handler";
import { FocusProvider } from "commonComponents/utilities/Focus/FocusProvider";
import { OwnerHeaderInfo } from "commonComponents/utilities/HeaderInfo/OwnerHeaderInfo";
import {
    IHeaderInfoResponse,
    IJobRunningTotalResponse,
} from "commonComponents/utilities/HeaderInfo/OwnerHeaderInfo.api.types";
import {
    JobPicker,
    ResizableJobPickerLoading,
} from "commonComponents/utilities/JobPicker/JobPicker";
import {
    IJobPickerHandler,
    JobPickerHandler,
} from "commonComponents/utilities/JobPicker/JobPicker.api.handler";
import {
    AccountSwitcherItem,
    IApiJobBuilderRequest,
    IApiJobFilterRequest,
    JobPickerFilterTypes,
    JobPickerJob,
    JobPickerLoadResponse,
    JobPickerPermissions,
    JobPickerSetResponse,
} from "commonComponents/utilities/JobPicker/JobPicker.api.types";
import { default as JobPickerFilterResponse } from "commonComponents/utilities/JobPicker/JobPicker.filter.api.json";
import {
    IJobPickerPersistantState,
    JobIdTypes,
    JobPickerDisplayModes,
    JobPickerSelectModes,
    JobSortOptions,
} from "commonComponents/utilities/JobPicker/JobPicker.types";
import { getCollapsed } from "commonComponents/utilities/JobPicker/JobPicker.utils";
import {
    clearJobPickerKeys,
    getJobPickerStorageKey,
} from "commonComponents/utilities/JobPicker/JobPickerWrapper";
import { MainNavigation } from "commonComponents/utilities/MainNavigation/MainNavigation";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";

import { FilterHandler, IFilterHandler } from "entity/filters/Filter/Filter.api.handler";
import {
    FilterEntity,
    FilterEntityType,
    IFilterFormValues,
    SelectedFilterItem,
} from "entity/filters/Filter/Filter.api.types";
import {
    getFilterString,
    getFilterStringFromItems,
    getSelectedFilterItems,
} from "entity/filters/filters.utils";
import { FrozenBuilder } from "entity/FrozenBuilder/FrozenBuilderWrapper";
import { JobHandler } from "entity/job/Job.api.handler";
import { JobRunningTotal, JobStatus } from "entity/job/Job.api.types";
import { JobHelpers } from "entity/job/Job/Job.helpers";
import { RouteMessageCompose } from "entity/message/MessageCompose/MessageComposeRoute";
import { RouteTemplate } from "entity/template/Template/RouteTemplate";
import { RouteTemplateFromTemplate } from "entity/template/TemplateFromTemplate/RouteTemplateFromTemplate";
import { TemplateListTabs } from "entity/template/TemplateList/TemplateListTab/TemplateListTab.api.types";

import "./ListPageLayout.less";

const jobFilters = new FilterEntity(JobPickerFilterResponse);

export interface IListPageChildProps {
    builderId: number;
    jobIds: number[];
    jobName: string;
    key: number;
    userId: number;
    isTemplateMode: boolean;
    loginType: BTLoginTypes;
    jobPickerReady: boolean;
    setSelectedJobId?: (jobId: number, orgId?: number, loadMissingJobs?: boolean) => Promise<void>;
    isAllJobsSelected: boolean;
    jobPickerCount: number;
    setJobPickerCollapsed: (isCollapsed: boolean) => void;
}

export interface IListPageLayoutProps {
    path?: string;
    exact?: boolean;
    filterHandler?: IFilterHandler;
    /** The component to render within the content section of the page */
    component: (listProps: IListPageChildProps, route: RouteComponentProps<any>) => React.ReactNode;
    jobPickerHandler?: IJobPickerHandler;
    jobHandler?: JobHandler;
    /**
     * Show or hide the job picker
     * @default true
     */
    showJobPicker?: boolean;
    selectedMenuItem: MenuItemName;

    /**
     * The job picker display mode, required if showJobPicker is true
     */
    jobPickerDisplayMode?: JobPickerDisplayModes;
    emptyStateHandler?: IEmptyStateHandler;
    appProviderHandler?: AppProviderHandler;
    /**
     * Single select to require a job be selected or
     * Multi select to all the selection of all jobs
     * @default JobPickerSelectModes.Multiple
     */
    jobPickerSelectMode?: JobPickerSelectModes;
    /**
     * If set to true, keep query params in the URL. Avoid using this unless you know what you are doing as query params
     * breaks recursive routing
     */
    keepQueryParams?: boolean;

    /**
     * If set to true, do not show the owner header info. This prop can be relocated to a client specific layout component in the future
     */
    hideOwnerHeaderInfo?: boolean;

    /**
     * Allows the global job to appear in the job picker
     */
    allowGlobalJob?: boolean;

    /**
     * Allows the global docs to appear in the job picker
     */
    allowGlobalDocs?: boolean;

    /**
     * Only set if the page is a job picker router
     */
    isJobPickerRouter?: boolean;
    hideNav?: boolean;
}

interface IListPageState {
    jobPickerData: JobPickerLoadResponse | undefined;
    headerInfo: IHeaderInfoResponse | undefined;
    jobRunningTotal: IJobRunningTotalResponse | undefined;
    selectedJobId: number;
    templatesOnly: boolean;
    sortOption: JobSortOptions;
    keywordSearch: string;
    selectedJobName: string;
    jobFilters: FilterEntity;
    collapsed: boolean;
    selectMode: JobPickerSelectModes;
    selectedFilters?: SelectedFilterItem[];
    selectedFilterId?: number;
    areSavedFiltersShowing: boolean;
    isFrozenBuilderNoticeVisible: boolean;
    frozenBuilderUserOverride?: number;
    isAccountClosed: boolean;
    isJobPickerLoading: boolean;
    loginPortalType: BTLoginTypes;
    keyInnerComponentUpdate: number; // temp solution to update inner component until we can start to leverage React Query
}

@track((_props: IListPageLayoutInternalProps, state: IListPageState) => ({
    jobId: state?.selectedJobId ?? JobIdTypes.NoJobs,
}))
class ListPageLayoutInternal extends PureComponent<IListPageLayoutInternalProps, IListPageState> {
    static defaultProps = {
        showHeaderInfo: true,
        jobPickerHandler: new JobPickerHandler(),
        jobHandler: new JobHandler(),
        filterHandler: new FilterHandler(),
        emptyStateHandler: new EmptyStateHandler(),
        showJobPicker: true,
        jobPickerSelectMode: JobPickerSelectModes.Multiple,
    };

    state: Readonly<IListPageState> = {
        jobPickerData: undefined,
        headerInfo: undefined,
        jobRunningTotal: undefined,
        selectedJobId: getSelectedJobId(),
        templatesOnly: getJobPickerState().isTemplateMode,
        sortOption: JobSortOptions.Alphabetic,
        keywordSearch: getJobPickerState().keywordSearch,
        selectedJobName: "",
        jobFilters: jobFilters,
        // todo remove  portal check
        collapsed: getCollapsed() && isInPortal({ builder: true }),
        selectMode: this.props.jobPickerSelectMode!,
        selectedFilters: undefined,
        areSavedFiltersShowing: false,
        isFrozenBuilderNoticeVisible: false,
        isAccountClosed: false,
        isJobPickerLoading: false,
        loginPortalType: getLoginTypeFromCurrentPortal(),
        keyInnerComponentUpdate: 1,
    };

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    private componentLoadTime: number | null = null;

    componentDidMount = async () => {
        // eslint-disable-next-line deprecate/member-expression
        const qsParams: queryString.ParsedQuery<string> = queryString.parse(window.location.search);
        const isFromWePay =
            Boolean(qsParams.uid && qsParams.checkout_id && qsParams.hosted) ||
            Boolean(qsParams.hosted && qsParams.preapproval_id);

        await this.checkForJobpickerJobIDQSParams();

        if (!isFromWePay && !qsParams.filesUploaded && !this.props.keepQueryParams) {
            this.clearQueryString();
        }

        // We also want to fetch this information to be available to use on the owner portal even it the picker itself is not shown.
        if (
            this.state.loginPortalType === BTLoginTypes.OWNER ||
            (this.props.showJobPicker && this.props.jobPickerDisplayMode)
        ) {
            await this.setupJobPicker();
            if (this.state.selectedFilters) {
                const selectedJobStatus = this.state.selectedFilters.find(
                    (item) => item.key === JobPickerFilterTypes.Other
                );
                if (selectedJobStatus) {
                    this.jobStatusFilter =
                        selectedJobStatus.selectedValue as JobStatusFilterTypes[];
                }
            }
        }
        this.componentLoadTime = new Date().getTime();
    };

    componentDidUpdate = async (prevProps: IListPageLayoutInternalProps) => {
        this.checkForNewVersion(prevProps.path);
        const redirectToSetupChanged =
            this.props.userInfo?.shouldRedirectToSetupExperience !==
            prevProps.userInfo?.shouldRedirectToSetupExperience;
        const builderIdChanged =
            prevProps.builderInfo?.builderId !== this.props.builderInfo?.builderId;
        const showJobPickerChanged =
            prevProps.showJobPicker !== this.props.showJobPicker &&
            this.state.jobPickerData === undefined;
        const globalJobChanged = prevProps.allowGlobalJob !== this.props.allowGlobalJob;
        const globalDocsChanged = prevProps.allowGlobalDocs !== this.props.allowGlobalDocs;
        const jobPickerSelectModeChanged =
            prevProps.jobPickerSelectMode !== this.props.jobPickerSelectMode;
        const fromGlobalDocs =
            (prevProps.jobPickerDisplayMode === JobPickerDisplayModes.Documents &&
                this.props.jobPickerDisplayMode === JobPickerDisplayModes.Videos) ||
            (prevProps.jobPickerDisplayMode === JobPickerDisplayModes.Videos &&
                this.props.jobPickerDisplayMode === JobPickerDisplayModes.Documents);
        const isJobPickerRouterChanged =
            prevProps.isJobPickerRouter !== this.props.isJobPickerRouter;

        if (redirectToSetupChanged) {
            const { userInfo } = this.props;
            if (userInfo?.shouldRedirectToSetupExperience && !userInfo?.isImpersonatingBuilder) {
                window.location.assign(routes.appBase + routes.builderSetup.setup);
            }
        } else if (builderIdChanged) {
            await this.setupJobPicker();
        } else if (
            showJobPickerChanged ||
            globalJobChanged ||
            globalDocsChanged ||
            jobPickerSelectModeChanged ||
            fromGlobalDocs ||
            isJobPickerRouterChanged
        ) {
            // set up job picker filter if it hasn't been loaded yet
            const shouldSetupJobpicker = !this.state.jobPickerData && !this.state.selectedFilters;
            this.resetJobIdForSpecialJobs();
            await this.handleJobPickerReload(this.props.jobPickerSelectMode!, shouldSetupJobpicker);
        }
    };

    private checkForNewVersion = (prevPath?: string) => {
        const newAppDetectedDate = BTLocalStorage.get("bt-string-newAppDetectedDate");
        if (newAppDetectedDate && this.props.appDefaultInfo?.deployRefreshRedirectWaitTime) {
            const detectedTime = new Date(newAppDetectedDate).getTime();
            const currentTime = Date.now();

            if (
                prevPath !== this.props.path &&
                this.componentLoadTime &&
                currentTime - detectedTime >=
                    this.props.appDefaultInfo?.deployRefreshRedirectWaitTime &&
                this.componentLoadTime < detectedTime
            ) {
                window.location.reload();
            }
        }
    };

    private resetJobIdForSpecialJobs = () => {
        const { selectedJobId } = this.state;
        const isNotGlobalDocs =
            !this.props.allowGlobalDocs && selectedJobId === JobIdTypes.GlobalDocs;
        const isNotGlobalJob = !this.props.allowGlobalJob && selectedJobId === JobIdTypes.GlobalJob;

        if (isNotGlobalDocs || isNotGlobalJob) {
            this.setState({
                selectedJobId: JobIdTypes.NoJobs,
            });
        }
    };

    private clearQueryString = () => {
        if (window.location.search.length > 0) {
            const url = window.location.href.split("?")[0];

            window.history.pushState(null, "", url);
        }
    };

    private checkForJobpickerJobIDQSParams = async () => {
        // Set Jobpicker JobID if the jobID in the query string does not equal the selected jobID
        // in BTLocalStorage

        // eslint-disable-next-line deprecate/member-expression
        const qsParams: queryString.ParsedQuery<string> = queryString.parse(window.location.search);

        if (qsParams.jobId) {
            const currentJobPickerState: IJobPickerPersistantState = BTLocalStorage.get(
                "bt-object-dangerousJobPickerState"
            );
            let newJobID: number = Number(qsParams.jobId);

            if (
                !(
                    currentJobPickerState.selectedJobIds.length === 1 &&
                    currentJobPickerState.selectedJobIds[0] === newJobID &&
                    currentJobPickerState.selectedJobIds.includes(newJobID) &&
                    isNaN(newJobID)
                ) &&
                this.props.builderInfo?.builderId &&
                this.state.selectedJobId !== newJobID
            ) {
                this.setState({
                    ...this.state,
                    selectedJobId: newJobID,
                });

                // Keep legacy pages (non-SPA) in sync
                // non-SPA storage
                const keywordSearch = this.state.keywordSearch;
                const filteredJobs = this.getSelectedFilteredJobs(newJobID, keywordSearch);
                if (!this.state.templatesOnly) {
                    const selectedJobStatus = getSelectedValue(
                        (await this.props.jobHandler!.get(newJobID)).jobInfo.jobStatus
                    );
                    if (selectedJobStatus === JobStatusTypes.Closed) {
                        this.jobStatusFilter = [JobStatusFilterTypes.Closed];
                    } else if (selectedJobStatus === JobStatusTypes.Presale) {
                        this.jobStatusFilter = [JobStatusFilterTypes.Presale];
                    } else if (selectedJobStatus === JobStatusTypes.Warranty) {
                        this.jobStatusFilter = [JobStatusFilterTypes.Warranty];
                    } else {
                        this.jobStatusFilter = [JobStatusFilterTypes.Open];
                    }
                }
                await this.getSetJobDetailsResponse(
                    {
                        jobId: newJobID,
                        builderId: this.props.builderInfo!.builderId,
                    },
                    filteredJobs,
                    false,
                    {
                        keywordFilter: keywordSearch,
                        otherFilter: this.state.templatesOnly
                            ? "Templates"
                            : this.jobStatusFilter.toString(),
                        leadStatusFilter: "",
                        jobGroupFilter: [],
                        projectManagerFilter: [],
                        selectedBuilderFilter: this.props.builderInfo.builderId,
                    },
                    this.state.templatesOnly
                );
            }
        }
    };

    private async getJobPickerInfo(): Promise<{
        filters: FilterEntity;
        filterString: string;
    }> {
        const jobPickerState = getJobPickerState();
        const filters = await this.loadFilters();
        const defaultFilter = filters.savedFilters.find((x) => x.isDefault);
        const storedFilter = this.state.templatesOnly
            ? jobPickerState.templateFilters
            : jobPickerState.filters;
        const filterString = storedFilter || (defaultFilter && defaultFilter.value) || "";
        return { filters, filterString };
    }

    private async setupJobPicker(): Promise<void> {
        try {
            const { filters, filterString } = await this.getJobPickerInfo();
            const selectedFilters = getSelectedFilterItems(filters, filterString);

            await this.loadJobs(filterString, filters);

            this.setState({
                selectedFilters,
            });
        } catch (e) {
            if (e instanceof NoAccessError) {
                // user has lost access to the job, fallback to no job selected
                await this.handleJobChangeById(JobIdTypes.NoJobs);
                window.location.assign(
                    getBaseRoute() +
                        routes.jobPicker.selectJobLink(
                            JobIdTypes.NoJobs,
                            routes.landingPage.getLink()
                        )
                );
            } else {
                // Handle other errors
                showAPIErrorMessage(e);
            }
        }
    }

    private jobStatusFilter: JobStatusFilterTypes[] = [JobStatusFilterTypes.Open];
    private setJobAPIHandlerResult?: IAPIHandlerResult<JobPickerSetResponse>;

    // ToDo: When the app is all SPA rename this method and remove legacy rest call or just replace the call with the set of localstorage
    private getSetJobDetailsResponse(
        selectedJobId: IApiJobBuilderRequest,
        selectedJobIdsRequest: IApiJobBuilderRequest[],
        persistFilters: boolean,
        filtersData: IApiJobFilterRequest,
        isTemplateMode: boolean
    ): Promise<JobPickerSetResponse | undefined> {
        this.clearSetJobAPIHandlerResult();

        let selectedJobIds = selectedJobIdsRequest;
        if (selectedJobIdsRequest.length === 1 && isSpecialJob(selectedJobIdsRequest[0].jobId)) {
            selectedJobIds = [];
        }

        // non-SPA storage
        this.setJobAPIHandlerResult = this.props.jobPickerHandler!.set(
            selectedJobId,
            selectedJobIds,
            persistFilters,
            filtersData,
            false
        );

        // SPA storage
        let jobIds = getSelectedJobIdsForJobPicker(
            selectedJobId.jobId,
            selectedJobIds,
            this.state.selectMode
        );
        if (typeof jobIds === "number") {
            jobIds = [jobIds];
        }
        const jobPickerState = getJobPickerState();
        jobIds = jobIds ?? [];
        const filters = this.tryParseFilterString(jobPickerState.filters);
        filters[JobPickerFilterTypes.Other] = filtersData.otherFilter;
        const filterString = JSON.stringify(filters);
        const storageValue = {
            ...jobPickerState,
            keywordSearch: filtersData.keywordFilter,
            filters: filterString,
            isTemplateMode: isTemplateMode,
            selectedJobIds: jobIds,
            isAllJobsSelected: jobIds.length > 1 || selectedJobId.jobId === JobIdTypes.AllJobs,
        };
        setJobPickerState(storageValue);
        return this.setJobAPIHandlerResult.response;
    }

    private tryParseFilterString = (filter: string) => {
        try {
            return JSON.parse(filter);
        } catch {
            return {};
        }
    };

    private async getJobRunningTotals(currentJobId: number): Promise<JobRunningTotal | undefined> {
        // if we don't have a valid job id and we don't have permission to see job running total
        // then just return and not call the API
        // need to test permission against headerInfo state because jobRunningTotal state can get chnage to undefine
        // also, must have the jobsite permission as well to hit GetJobRunningTotal
        if (
            currentJobId <= JobIdTypes.AllJobs ||
            !this.state.headerInfo?.jobRunningTotal?.hasRunningTotalPermission ||
            !this.state.headerInfo?.jobRunningTotal?.hasJobsitePermission
        ) {
            return;
        }
        const jobHandler = this.props.jobHandler;
        const jobRunningTotal = await jobHandler!.getJobRunningTotal(currentJobId);
        return jobRunningTotal;
    }

    private clearSetJobAPIHandlerResult() {
        if (this.setJobAPIHandlerResult) {
            this.setJobAPIHandlerResult.cancel();
            this.setJobAPIHandlerResult = undefined;
        }
    }

    private getSelectedFilteredJobs(jobID: number, keywordSearch: string) {
        const selectedFilteredJobs = filterJobsList(
            this.state.jobPickerData?.jobs ?? [],
            // If we can't show the job picker, users can't edit the keyword search. Therefore, default to ""
            this.props.showJobPicker ? keywordSearch : "",
            this.state.templatesOnly
        );
        const filteredJobs: IApiJobBuilderRequest[] =
            jobID === JobIdTypes.AllJobs
                ? selectedFilteredJobs.map((j) => ({
                      jobId: j.jobId,
                      builderId: j.builderId,
                      jobStatus: j.jobStatus,
                  }))
                : [
                      {
                          jobId: jobID,
                          builderId:
                              selectedFilteredJobs.find((j) => j.jobId === jobID)?.builderId ??
                              this.props.builderInfo!.builderId,
                          jobStatus: selectedFilteredJobs.find((j) => j.jobId === jobID)?.jobStatus,
                      },
                  ];

        return filteredJobs;
    }

    private handleOwnerJobChange = async (id: number) => {
        try {
            const jobPickerState = getJobPickerState();
            const storageValue = {
                ...jobPickerState,
                selectedJobIds: [id],
                isAllJobsSelected: false,
            };

            setJobPickerState(storageValue);

            await this.props.jobPickerHandler!.switchOwner(id);
            const loc = window.location;
            // refresh page without QS (or post values)
            let url = loc.href.split("?")[0];
            window.location.assign(url);
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private handleJobChangeById = async (
        jobId: number,
        _orgId?: number,
        loadMissingJobs?: boolean
    ) => {
        const { userInfo } = this.props;
        if (userInfo && [BTLoginTypes.OWNER, BTLoginTypes.CONTACT].includes(userInfo.loginType)) {
            await this.handleOwnerJobChange(jobId);
        } else {
            await this.handleJobChangeByIdForJobPicker(jobId, loadMissingJobs);
        }
    };

    private handleJobChangeByIdForJobPicker = async (jobId: number, loadMissingJobs?: boolean) => {
        const { jobPickerData, keywordSearch } = this.state;
        let job = null;

        if (jobPickerData?.jobs) {
            job = jobPickerData.jobs.find((j) => j.jobId === jobId);
        }

        if (jobId === JobIdTypes.NoJobs) {
            job = new JobPickerJob({
                jobId: JobIdTypes.NoJobs,
                jobName: "No Job selected",
                builderId: this.props.builderInfo!.builderId,
            });
        }

        if (job) {
            await this.handleJobChange(job, keywordSearch);
        } else if (loadMissingJobs) {
            try {
                const jobInfo = await this.props.jobHandler!.get(jobId);
                const status: JobStatus = getSelectedValue(jobInfo.jobInfo.jobStatus);
                const filters = this.setFiltersForMissingJob(this.state.selectedFilters, status);

                await this.getSetJobDetailsResponse(
                    {
                        builderId: this.props.builderInfo!.builderId!,
                        jobId: this.state.selectedJobId,
                    },
                    [],
                    false,
                    {
                        keywordFilter: "",
                        otherFilter: this.state.templatesOnly
                            ? "Templates"
                            : this.jobStatusFilter.toString(),
                        leadStatusFilter: "",
                        selectedBuilderFilter: this.props.builderInfo!.builderId!,
                    },
                    this.state.templatesOnly
                );

                const jobData = await this.loadJobs(
                    getFilterStringFromItems(filters),
                    undefined,
                    JobPickerSelectModes.Multiple,
                    JobPickerDisplayModes.JobPickerRouter
                );
                if (jobData?.jobs) {
                    job = jobData.jobs.find((j) => j.jobId === jobId);
                    if (job) {
                        await this.handleJobChange(job, "");
                    }
                }
            } catch (e) {
                showAPIErrorMessage(e);
            }
        } else {
            void message.error("Could not select job");
        }
    };

    private setFiltersForMissingJob = (
        filters: SelectedFilterItem[] | undefined,
        status: JobStatus
    ) => {
        if (filters) {
            const orgFilter = filters?.find((f) => f.key === JobPickerFilterTypes.Other);
            let updatedStatusFilter = orgFilter!.selectedValue as number[];
            const expectedStatus = mapJobStatusToJobStatusFilterTypes(status);
            if (
                orgFilter &&
                orgFilter.selectedValue &&
                (orgFilter.selectedValue as number[]) &&
                !(orgFilter.selectedValue as number[]).includes(expectedStatus)
            ) {
                updatedStatusFilter = [...updatedStatusFilter, expectedStatus];
            }

            filters = filters.map((item) => {
                // add the missing status if needed to the "other" (job status)
                if (item.key === JobPickerFilterTypes.Other) {
                    return new SelectedFilterItem({
                        ...item,
                        selectedValue: updatedStatusFilter,
                    });
                } else {
                    // clear out all other filters
                    return new SelectedFilterItem({
                        ...item,
                        selectedValue: "",
                    });
                }
            });
            this.setState({
                keywordSearch: "",
                selectedFilters: filters,
            });
        }
        return filters;
    };

    private handleJobChange = async (
        job: JobPickerJob,
        keywordSearch: string,
        props?: RouteComponentProps
    ) => {
        // Update the legacy storage keys and session side selected job ids so state is preserved
        // when switching SPA -> non-SPA. Remove all of this below when deleting the legacy non-SPA
        // job picker (JobPickerWrapper)

        // BEGIN LEGACY BACKWARDS COMPATABILITY CODE
        // eslint-disable-next-line no-restricted-globals
        localStorage.setItem(
            getJobPickerStorageKey(
                LocalStorageKeys.JobPickerKeywordSearch,
                false,
                this.props.builderInfo!.builderId
            ),
            keywordSearch
        );
        const filteredJobs = this.getSelectedFilteredJobs(job.jobId, keywordSearch);
        const jobStatusFilterValue = mapJobStatusToJobStatusFilterTypes(job.jobStatus);
        let newFilters = this.state.selectedFilters;
        if (job.jobId > 0 && !this.jobStatusFilter.includes(jobStatusFilterValue)) {
            this.jobStatusFilter.push(jobStatusFilterValue);
            newFilters = newFilters
                ? newFilters.map((item) => {
                      if (item.key === JobPickerFilterTypes.Other) {
                          return new SelectedFilterItem({
                              ...item,
                              selectedValue: [
                                  ...(item.selectedValue! as number[]),
                                  jobStatusFilterValue,
                              ],
                          });
                      } else {
                          return new SelectedFilterItem({
                              ...item,
                          });
                      }
                  })
                : undefined;
        }

        // We need to set the job in the backend to stay in sync with non-SPA pages.
        const resp = await this.getSetJobDetailsResponse(
            {
                jobId: job.jobId,
                builderId: job.builderId,
            },
            filteredJobs,
            true,
            {
                keywordFilter: keywordSearch,
                otherFilter: this.state.templatesOnly
                    ? "Templates"
                    : this.jobStatusFilter.join(","),
                leadStatusFilter: "",
                jobGroupFilter: [],
                projectManagerFilter: [],
                selectedBuilderFilter: this.props.builderInfo!.builderId!,
            },
            this.state.templatesOnly
        ).catch(() => {
            // This is for backwards compatability. It's not super important that this call passes,
            // as the worst that would happen is the selected job isn't consistent between SPA
            // and non-SPA pages.
        });
        // END LEGACY BACKWARDS COMPATABILITY CODE

        // make new call to JobRunningTotal because we do not want to rely on getSetJobDetailsResponse moving forward
        // since it is a legacy API that relies on the job id being set in the backend.
        let currentJobRunningTotal = await this.getJobRunningTotals(job.jobId);

        if (resp) {
            this.setState({
                keywordSearch,
                selectedJobId: job.jobId,
                selectedJobName: job.jobName,
                jobRunningTotal: currentJobRunningTotal,
                isJobPickerLoading: false,
                selectedFilters: newFilters,
                jobPickerData: {
                    ...this.state.jobPickerData!,
                    ownerInfo: resp.ownerInfo,
                },
            });
        }
        const shouldRedirectToLandingPage = JobHelpers.shouldHandleAsFullPage(
            this.props.builderInfo?.flags?.bdsJobDetailsToFullPage,
            window.location.pathname
        );
        if (shouldRedirectToLandingPage && props) {
            props.history.push(routes.landingPage.path);
        }
    };

    private getPermissions(permissions: JobPickerPermissions): JobPickerPermissions {
        const shouldOverride = JobHelpers.shouldHandleAsFullPage(
            this.context?._globalInfo?.builderInfo?.flags.bdsJobDetailsToFullPage ?? false,
            window.location.pathname
        );
        if (!shouldOverride) return permissions;
        // override permissions if we're in a jobpicker thats rendered on a flatroute job details page :)
        return {
            ...permissions,
            canViewJobDetails: false,
        };
    }

    private handleBuilderChange = async (newBuilderId: number) => {
        this.setState({
            selectedJobId: JobIdTypes.NoJobs,
            keywordSearch: "",
        });

        const { templatesOnly } = this.state;

        // Update the legacy storage keys and session side selected job ids so state is preserved
        // when switching SPA -> non-SPA. Remove all of this below when deleting the legacy non-SPA
        // job picker

        // BEGIN LEGACY BACKWARDS COMPATABILITY CODE
        // todo does this work when all jobs are selected?
        const filterRequest: IApiJobFilterRequest = {
            keywordFilter: "", // TODO: get value from BTSearch for keyword (note: todo from job picker wrapper - do we need to do this?)
            otherFilter: templatesOnly ? "Templates" : this.jobStatusFilter.toString(),
            leadStatusFilter: "",
            jobGroupFilter: [],
            projectManagerFilter: [],
            selectedBuilderFilter: newBuilderId,
        };

        await this.getSetJobDetailsResponse(
            {
                jobId: JobIdTypes.NoJobs,
                builderId: newBuilderId,
            },
            [],
            true,
            filterRequest,
            this.state.templatesOnly
        );

        // END LEGACY BACKWARDS COMPATABILITY CODE
        await this.context?.reloadGlobalInfo();
    };

    private closeModal = (matchProps: RouteComponentProps) => {
        matchProps.history.replace(matchProps.match.url);
    };

    private closeModalRefreshJobs = async (matchProps: RouteComponentProps) => {
        const defaultFilter =
            this.state.jobFilters && this.state.jobFilters.savedFilters.find((x) => x.isDefault);
        const initJobPickerState = getJobPickerState();
        const filterString =
            (!initJobPickerState.isTemplateMode && initJobPickerState.filters) ||
            (initJobPickerState.isTemplateMode && initJobPickerState.templateFilters) ||
            (defaultFilter && defaultFilter.value) ||
            "";
        this.closeModal(matchProps);
        await this.loadJobs(filterString);
        this.handleUpdateInnerComponentKey();
    };

    /**
     * This handleUpdateInnerComponentKey method updates a key value that is used to trigger the inner component to
     * completely refresh to keep inner data in sync with data changes.
     * example: when a job name gets changed from job picker this will force the inner component (list page) to update.
     * and refetch the latest data with the new job name.
     * This is a temporary solution to update stale data until we are able to use React Query across the application.
     */
    private handleUpdateInnerComponentKey = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                keyInnerComponentUpdate: prevState.keyInnerComponentUpdate + 1,
            };
        });
    };

    private handleCollapse = (isCollapsing: boolean) => {
        this.setState({
            collapsed: isCollapsing,
        });
    };

    private handleDismissBanner = async () => {
        const entityType = this.state.templatesOnly
            ? EmptyStateEntity.JobTemplates
            : EmptyStateEntity.Jobs;
        await this.props.emptyStateHandler!.dismissAlert(entityType);
    };

    private getSelectedFilteredJobIds = memoizeOne(
        (
            selectedJobId: number,
            jobPickerData: JobPickerLoadResponse | undefined,
            keywordSearch: string,
            templatesOnly: boolean
        ) => {
            if (selectedJobId === JobIdTypes.AllJobs) {
                const selectedFilteredJobs = filterJobsList(
                    jobPickerData?.jobs ?? [],
                    // If we can't show the job picker, users can't edit the keyword search. Therefore, default to ""
                    this.props.showJobPicker ? keywordSearch : "",
                    templatesOnly
                );
                // Return a list of all jobs, but filtering out special jobs
                return selectedFilteredJobs.map((j) => j.jobId).filter((jobId) => jobId > 0);
            } else if (selectedJobId === JobIdTypes.GlobalJob && this.props.allowGlobalJob) {
                return [JobIdTypes.GlobalJob];
            } else if (selectedJobId === JobIdTypes.GlobalDocs && this.props.allowGlobalDocs) {
                return [JobIdTypes.GlobalDocs];
            } else if (selectedJobId <= 0) {
                return [];
            } else {
                return [selectedJobId];
            }
        }
    );

    private handleSwitchError = (e: any, newUserId: number) => {
        if (e instanceof APIError && e.response.data.switchBuilderFrozen) {
            // Builder frozen, show the frozen screen
            let qsDict = {
                dMethod: "iframe",
                overrideSessionUserLoginID: newUserId,
            };
            // eslint-disable-next-line no-restricted-syntax
            (window as any).btMaster.btDialogs.createAndFireDialog({
                dBoxMethod: "iframe",
                width: "1200px",
                height: "800px",
                // eslint-disable-next-line no-restricted-syntax
                src: (window as any).QSHandler.CreateQueryStringFromDictionary(
                    // eslint-disable-next-line no-restricted-syntax
                    (window as any).Global.PageUrlHandler.MiscPageUrl.FrozenBuilderNotice,
                    qsDict
                ),
                showCloseX: "true",
                showLoadingSpinner: "true",
            });
        } else {
            showAPIErrorMessage(e);
        }
    };

    private handleAccountChange = async (items: AccountSwitcherItem[]) => {
        try {
            if (items.length > 1) {
                void this.handleBuilderChange(CommonConstants.EmptyInteger);
                return;
            }
            const resp = await this.props.jobPickerHandler!.switchAccount(items[0].globalUserId);
            if (getLoginTypeFromCurrentPortal() === items[0].loginType) {
                clearJobPickerKeys(this.props.builderInfo!.builderId!);
                await this.handleBuilderChange(items[0].builderId);
            } else {
                // Todo: Remove the following when we have our dynamic context so we act like full SPA
                if (resp.refreshUrl) {
                    window.location.assign(resp.refreshUrl);
                } else {
                    // eslint-disable-next-line deprecate/member-expression
                    routesWebforms.refreshPage();
                }

                // Todo: just call load jobs like the following when we have our dynamic context instead of reloading the page which goes against SPA
                //  this.setState({
                //      selectedJobId: JobIdTypes.NoJobs,
                //      keywordSearch: "",
                //  });

                // Load jobs after we switched accounts to update the job list.
                // const defaultFilter =
                //     this.state.jobFilters &&
                //     this.state.jobFilters.savedFilters.find((x) => x.isDefault);
                // const filterString = (defaultFilter && defaultFilter.value) || "";
                // await this.loadJobs(filterString);
                // todo: Make sure main navigation is updated. Need to create a dynamic context so that we can change the UserInfoContext to update to the new account.
            }
        } catch (e) {
            this.handleSwitchError(e, items[0].globalUserId);
        }
    };

    private handleJobPickerReload = async (
        selectionMode: number,
        shouldSetupJobpicker: boolean
    ) => {
        if (shouldSetupJobpicker) {
            await this.setupJobPicker();
        } else {
            await this.loadJobs(
                getFilterStringFromItems(this.state.selectedFilters),
                this.state.jobFilters,
                selectionMode
            );
        }
    };

    private handleTemplateModeChanged = async (
        templatesOnly: boolean,
        matchProps: RouteComponentProps,
        builderID: number,
        initialTemplateTab: TemplateListTabs = TemplateListTabs.MyTemplates
    ) => {
        const jobIdToSet = templatesOnly ? JobIdTypes.AllJobs : JobIdTypes.NoJobs;

        const jobIds =
            this.state.jobPickerData!.jobs.length === 1 && jobIdToSet === JobIdTypes.AllJobs
                ? []
                : this.state.jobPickerData!.jobs.map((x) => ({
                      jobId: x.jobId,
                      builderId: x.builderId,
                  }));

        await this.getSetJobDetailsResponse(
            { builderId: 0, jobId: jobIdToSet },
            jobIds,
            true,
            {
                keywordFilter: this.state.keywordSearch,
                otherFilter: templatesOnly ? "Templates" : JobStatusFilterTypes.Open.toString(),
                leadStatusFilter: "",
                selectedBuilderFilter: builderID,
            },
            templatesOnly
        );

        // this requires a full page reload until we can trigger the menu to update
        if (templatesOnly) {
            window.location.assign(
                routes.appBase + routes.template.getListLink(initialTemplateTab)
            );
        } else {
            window.location.assign(getBaseRoute() + routes.landingPage.getLink());
        }
    };

    /**
     * TODO: update other APIs to not rely on a selectedJobId to be set in the back end and instead have it passed in from the frontend using browser session or react context.
     * This will then allow users to open a new browser tab and be able to choose a new job to view in the new tab while still being able to see another job in the old tab.
     */
    private loadJobs = async (
        filterString: string,
        jobFiltersParam?: FilterEntity,
        selectMode?: JobPickerSelectModes,
        displayMode?: JobPickerDisplayModes
    ) => {
        let mode = displayMode ?? this.props.jobPickerDisplayMode;

        // Because we don't show the job picker in the owner portal, the job information
        // is often not set so it's not available unless query it separately.
        // Mainly list pages don't show the job name in their headers without this
        // but that has caused enough issue on its own.
        if (!mode && this.state.loginPortalType === BTLoginTypes.OWNER) {
            mode = JobPickerDisplayModes.Minimal;
        }

        if (!mode) {
            return;
        }

        const selectedMode = selectMode ?? this.state.selectMode;

        this.setState({
            isJobPickerLoading: true,
        });

        const jobPickerData = await this.props.jobPickerHandler!.get(
            String(this.props.builderInfo!.builderId!),
            this.state.selectedJobId,
            this.props.allowGlobalJob ?? false,
            selectedMode,
            mode,
            filterString,
            this.state.templatesOnly,
            false
        );

        const jobFilters = jobFiltersParam ?? (await this.loadFilters());

        // If we find that the current selected job is no longer a selected job in the job picker,
        // then we need to reset selectedJobId to NoJobs (or AllJobs for owners as they don't have a true job picker)
        const currentJob = jobPickerData.jobs.find((j) => j.jobId === this.state.selectedJobId);
        let selectedJobId = this.state.selectedJobId;
        if (!currentJob) {
            if (getCurrentPortalType() === PortalType.OWNER) {
                // TODO: This should be based off of "SelectMode" of "single" or "multi" select and not portal I believe
                selectedJobId = JobIdTypes.AllJobs;
            } else {
                selectedJobId = JobIdTypes.NoJobs;
            }
        }

        const initJobPickerState = getJobPickerState();
        let selectedJobIds =
            getSelectedJobIdsForJobPicker(
                selectedJobId,
                jobPickerData.jobs,
                this.state.selectMode
            ) ?? [];
        if (typeof selectedJobIds === "number") {
            selectedJobIds = [selectedJobIds];
        }

        const storageValue = {
            keywordSearch: this.state.keywordSearch,
            filters: !this.state.templatesOnly ? filterString : initJobPickerState.filters,
            templateFilters: this.state.templatesOnly
                ? filterString
                : initJobPickerState.templateFilters,
            isTemplateMode: this.state.templatesOnly,
            selectedJobIds: selectedJobIds,
            isAllJobsSelected: selectedJobIds.length > 1 || selectedJobId === JobIdTypes.AllJobs,
        };
        setJobPickerState(storageValue);

        // BEGIN LEGACY BACKWARDS COMPATABILITY CODE
        // eslint-disable-next-line no-restricted-globals
        localStorage.setItem(
            getJobPickerStorageKey(
                LocalStorageKeys.CurrentJobPickerFilter,
                this.state.templatesOnly,
                this.props.builderInfo!.builderId
            ),
            filterString
        );

        const newJobPickerData = {
            ...jobPickerData,
            jobs: filterJobsList(jobPickerData.jobs, "", this.state.templatesOnly),
        };
        this.setState({
            jobFilters: jobFilters,
            headerInfo: jobPickerData.headerInfo,
            jobRunningTotal: jobPickerData.headerInfo?.jobRunningTotal,
            jobPickerData: newJobPickerData,
            selectedJobId,
            selectedJobName: currentJob?.jobName ?? "",
            isJobPickerLoading: false,
            selectMode: selectedMode,
        });
        return newJobPickerData;
    };

    private loadFilters = async () => {
        const filterType = this.state.templatesOnly
            ? FilterEntityType.TemplateJobPicker
            : FilterEntityType.JobPicker;
        const selectedJobs = this.getSelectedFilteredJobIds(
            this.state.selectedJobId,
            this.state.jobPickerData,
            this.state.keywordSearch,
            this.state.templatesOnly
        );
        let selectedJob = selectedJobs.length === 1 ? selectedJobs[0] : null;

        if (selectedJob === JobIdTypes.GlobalJob || selectedJob === JobIdTypes.GlobalDocs) {
            selectedJob = null;
        }

        return await this.props.filterHandler!.get(filterType, undefined, selectedJob);
    };

    private handleKeywordClear = async () => {
        await this.handleKeywordUpdate("");
    };

    private handleKeywordUpdate = async (newKeywordSearch: string) => {
        // BEGIN LEGACY BACKWARDS COMPATABILITY CODE
        // eslint-disable-next-line no-restricted-globals
        localStorage.setItem(
            getJobPickerStorageKey(
                LocalStorageKeys.JobPickerKeywordSearch,
                false,
                this.props.builderInfo!.builderId
            ),
            newKeywordSearch
        );

        const filteredJobs = this.getSelectedFilteredJobs(this.state.selectedJobId, "").filter(
            (j) => !isSpecialJob(j.jobId)
        );

        await this.getSetJobDetailsResponse(
            { builderId: this.props.builderInfo!.builderId!, jobId: this.state.selectedJobId },
            filteredJobs,
            false,
            {
                keywordFilter: newKeywordSearch,
                otherFilter: this.state.templatesOnly
                    ? "Templates"
                    : this.jobStatusFilter.toString(),
                leadStatusFilter: "",
                selectedBuilderFilter: this.props.builderInfo!.builderId!,
            },
            this.state.templatesOnly
        );

        // END LEGACY BACKWARDS COMPATABILITY CODE

        this.setState({
            keywordSearch: newKeywordSearch,
        });
        let selectedJobIds =
            getSelectedJobIdsForJobPicker(
                this.state.selectedJobId,
                this.state.jobPickerData?.jobs ?? [],
                this.state.selectMode
            ) ?? [];
        if (typeof selectedJobIds === "number") {
            selectedJobIds = [selectedJobIds];
        }

        const initJobPickerState = getJobPickerState();
        const filterString = getFilterStringFromItems(this.state.selectedFilters);

        const storageValue = {
            keywordSearch: newKeywordSearch,
            filters: !this.state.templatesOnly ? filterString : initJobPickerState.filters,
            templateFilters: this.state.templatesOnly
                ? filterString
                : initJobPickerState.templateFilters,
            isTemplateMode: this.state.templatesOnly,
            selectedJobIds: selectedJobIds,
            isAllJobsSelected:
                selectedJobIds.length > 1 || this.state.selectedJobId === JobIdTypes.AllJobs,
        };
        setJobPickerState(storageValue);
        await this.loadJobs(getFilterStringFromItems(this.state.selectedFilters));
    };

    private debounceHandleKeywordUpdate = debounce(this.handleKeywordUpdate, 1000);

    private handleFilterSubmit = async (filterValues: IFilterFormValues, keywordSearch: string) => {
        this.setState(
            {
                keywordSearch,
                selectedFilters: filterValues.items,
                selectedFilterId: filterValues.savedFilter.id,
            },
            async () => {
                const filterString = getFilterString(filterValues);
                await this.loadJobs(filterString);
                // Update the persisted filters. This is so that the jobs list is udpated when creating a new entity with all jobs selected
                const jobIds =
                    this.state.jobPickerData!.jobs.length === 1 &&
                    this.state.selectedJobId === JobIdTypes.AllJobs
                        ? []
                        : this.state.jobPickerData!.jobs.map((x) => ({
                              jobId: x.jobId,
                              builderId: x.builderId,
                          }));
                const jobGroupFilterItem = filterValues.items.find(
                    (i) => i.key === JobPickerFilterTypes.Neighborhood
                );
                const projectManagerFilterItem = filterValues.items.find(
                    (i) => i.key === JobPickerFilterTypes.ProjectManager
                );
                const selectedStatusFilters = filterValues.items.find(
                    (x) => x.key === JobPickerFilterTypes.Other
                )?.selectedValue;
                if (Array.isArray(selectedStatusFilters)) {
                    this.jobStatusFilter = selectedStatusFilters;
                } else {
                    this.jobStatusFilter = [JobStatusFilterTypes.Open];
                }
                this.setJobAPIHandlerResult = this.props.jobPickerHandler!.set(
                    {
                        jobId: this.state.selectedJobId,
                        builderId: this.props.builderInfo!.builderId,
                    },
                    jobIds,
                    true,
                    {
                        keywordFilter: this.state.keywordSearch,
                        otherFilter: this.state.templatesOnly
                            ? "Templates"
                            : this.jobStatusFilter.toString(),
                        leadStatusFilter: "",
                        jobGroupFilter: jobGroupFilterItem
                            ? (jobGroupFilterItem.selectedValue as number[]).map((s: number) =>
                                  s.toString()
                              )
                            : undefined,
                        projectManagerFilter: projectManagerFilterItem
                            ? (projectManagerFilterItem.selectedValue as number[]).map(
                                  (s: number) => s.toString()
                              )
                            : undefined,
                        selectedBuilderFilter: this.props.builderInfo!.builderId,
                    },
                    false
                );
                void message.success("Results Updated");
            }
        );
    };

    private handleSavedFiltersUpdated = (jobFilters: FilterEntity) => {
        this.setState({ jobFilters });
    };

    private handleSavedFiltersVisibleChange = (visible: boolean) => {
        this.setState({
            areSavedFiltersShowing: visible,
        });
    };

    private handleSortChanged = async (sortOption: JobSortOptions) => {
        this.setState({ sortOption });
        await this.props.jobPickerHandler!.setSort(sortOption);
        if (this.state.jobPickerData?.isListLimited) {
            await this.loadJobs(getFilterStringFromItems(this.state.selectedFilters));
        }
    };

    render() {
        // We have to pull children out here otherwise it will only render children in Route
        const {
            component: Component,
            jobPickerDisplayMode,
            hideOwnerHeaderInfo,
            envInfo,
            ...rest
        } = this.props;
        /**
         * Filter the jobs list to get array of only the real job ids.
         * To pass into the page body through context for page filtering.
         */
        const filteredJobIds = this.getSelectedFilteredJobIds(
            this.state.selectedJobId,
            this.state.jobPickerData,
            this.state.keywordSearch,
            this.state.templatesOnly
        );

        const summaryUrl = this.state.templatesOnly
            ? routes.template.getListLink()
            : routes.landingPage.getLinkForLoginType(getLoginTypeFromCurrentPortal(), false);
        const isImpersonatingUser =
            this.props.userInfo?.isImpersonatingBuilder ||
            this.props.userInfo?.isBuilderImpersonatingOwner;
        const jobsInPicker =
            this.state.jobPickerData?.jobs.filter((j) => j.jobId !== JobIdTypes.AllJobs).length ??
            0;

        return (
            <Route
                {...rest}
                render={(matchProps) => {
                    // Only render main navigation if we aren't in a iframe
                    // todo (this can be removed once we've migrated all modals to react)
                    return (
                        <>
                            <div
                                className={classNames("ListPageLayout", {
                                    "ListPageLayout-with-sidebar": this.props.showJobPicker,
                                    "ListPageLayout-sidebar-collapsed":
                                        this.props.showJobPicker && this.state.collapsed === true,
                                })}
                            >
                                {!isImpersonatingUser &&
                                    this.props.userInfo?.globalUserId &&
                                    this.props.userInfo.globalUserId > 0 &&
                                    !this.props.userInfo.hasAcceptedTermsAndPolicy && (
                                        <AcceptTermsAndPolicies
                                            handler={new AcceptTermsAndPoliciesHandler()}
                                            closeHeaderModal={() => {}}
                                            {...matchProps}
                                        />
                                    )}

                                {/** Check for builderInfo and builderId before calling MainNavigation
                                 * This allows for page error to forward to login page if there is not a session
                                 * otherwise we get a breaking error in the UI.
                                 */}
                                {this.props.builderInfo?.builderId && !this.props.hideNav && (
                                    <>
                                        <MainNavigation
                                            fromReact
                                            builderId={this.props.builderInfo.builderId}
                                            userId={this.props.userInfo!.globalUserId!}
                                            jobId={this.state.selectedJobId}
                                            isTemplateMode={this.state.templatesOnly}
                                            loginType={this.state.loginPortalType}
                                            selectedMenuItem={this.props.selectedMenuItem}
                                            className="ListPageLayout--nav"
                                            {...matchProps}
                                        />

                                        <ShowOnPortal
                                            owner
                                            render={() =>
                                                !hideOwnerHeaderInfo && (
                                                    <OwnerHeaderInfo
                                                        builderId={
                                                            this.props.builderInfo!.builderId
                                                        }
                                                        jobId={getSelectedJobId()}
                                                        loadNewJob={!this.state.isJobPickerLoading}
                                                        setSelectedJobId={this.handleJobChangeById}
                                                        {...matchProps}
                                                    />
                                                )
                                            }
                                        />
                                    </>
                                )}

                                {this.props.showJobPicker && (
                                    <div className="ListPageLayout--sidebar">
                                        {this.state.jobPickerData === undefined && (
                                            <ResizableJobPickerLoading />
                                        )}
                                        {this.state.jobPickerData !== undefined &&
                                            jobPickerDisplayMode && (
                                                <>
                                                    <JobPicker
                                                        displayMode={jobPickerDisplayMode}
                                                        jobs={this.state.jobPickerData.jobs}
                                                        jobRunningTotal={this.state.jobRunningTotal}
                                                        selectedJobIds={this.state.selectedJobId}
                                                        onJobPickerJobChanged={(
                                                            job,
                                                            keywordSearch
                                                        ) =>
                                                            this.handleJobChange(
                                                                job,
                                                                keywordSearch,
                                                                matchProps
                                                            )
                                                        }
                                                        onJobPickerBuilderChanged={
                                                            this.handleBuilderChange
                                                        }
                                                        permissions={this.getPermissions(
                                                            this.state.jobPickerData.permissions
                                                        )}
                                                        ownerInfo={
                                                            this.state.jobPickerData.ownerInfo
                                                        }
                                                        templatesOnly={this.state.templatesOnly}
                                                        onTemplateModeChanged={(
                                                            templatesOnly,
                                                            _,
                                                            tab
                                                        ) =>
                                                            this.handleTemplateModeChanged(
                                                                templatesOnly,
                                                                matchProps,
                                                                this.props.builderInfo!.builderId,
                                                                tab
                                                            )
                                                        }
                                                        selectMode={this.state.selectMode}
                                                        filters={this.state.jobFilters}
                                                        selectedFilterId={
                                                            this.state.selectedFilterId
                                                        }
                                                        selectedFilters={this.state.selectedFilters}
                                                        onFilterSubmit={this.handleFilterSubmit}
                                                        onSortChanged={this.handleSortChanged}
                                                        sortOption={this.state.sortOption}
                                                        keywordSearch={
                                                            this.props.showJobPicker
                                                                ? this.state.keywordSearch
                                                                : ""
                                                        }
                                                        onKeywordClear={this.handleKeywordClear}
                                                        listMetadata={
                                                            this.state.jobPickerData.listMetadata
                                                        }
                                                        onDismissBanner={this.handleDismissBanner}
                                                        availableAccounts={
                                                            this.state.jobPickerData
                                                                .availableAccounts
                                                        }
                                                        onAccountChange={this.handleAccountChange}
                                                        areSavedFiltersShowing={
                                                            this.state.areSavedFiltersShowing
                                                        }
                                                        onSavedFilterVisibleChange={
                                                            this.handleSavedFiltersVisibleChange
                                                        }
                                                        onSavedFiltersUpdated={
                                                            this.handleSavedFiltersUpdated
                                                        }
                                                        setCollapsed={this.handleCollapse}
                                                        collapsed={this.state.collapsed}
                                                        loading={this.state.isJobPickerLoading}
                                                        width={BTLocalStorage.get(
                                                            "bt-number-sidebarWidth"
                                                        )}
                                                        headerInfo={this.state.headerInfo}
                                                        isConnectedToAccounting={
                                                            this.state.jobPickerData
                                                                .isConnectedToAccounting
                                                        }
                                                        fromReact
                                                        builderId={
                                                            this.props.builderInfo!.builderId
                                                        }
                                                        userId={this.props.userInfo!.globalUserId!}
                                                        jobId={this.state.selectedJobId}
                                                        isTemplateMode={this.state.templatesOnly}
                                                        isListLimited={
                                                            this.state.jobPickerData.isListLimited
                                                        }
                                                        onServerKeywordUpdate={
                                                            this.debounceHandleKeywordUpdate
                                                        }
                                                        summaryUrl={summaryUrl}
                                                        {...matchProps}
                                                    />
                                                    <RouteRelative
                                                        path="/JobPickerActions"
                                                        render={(routeProps) => (
                                                            <>
                                                                <RouteJob
                                                                    beforeClose={() =>
                                                                        this.closeModalRefreshJobs(
                                                                            matchProps
                                                                        )
                                                                    }
                                                                    history={routeProps.history}
                                                                    parentRoute={
                                                                        routeProps.match.path
                                                                    }
                                                                />
                                                                <RouteTemplate
                                                                    modalConfig={{
                                                                        beforeClose: () =>
                                                                            this.closeModalRefreshJobs(
                                                                                matchProps
                                                                            ),
                                                                        parentRoute:
                                                                            routeProps.match.path,
                                                                    }}
                                                                />
                                                                <RouteTemplateFromTemplate
                                                                    modalConfig={{
                                                                        beforeClose: () =>
                                                                            this.closeModalRefreshJobs(
                                                                                matchProps
                                                                            ),
                                                                        parentRoute:
                                                                            routeProps.match.path,
                                                                    }}
                                                                />
                                                                <RouteMessageCompose
                                                                    modalConfig={{
                                                                        beforeClose: () =>
                                                                            this.closeModal(
                                                                                matchProps
                                                                            ),
                                                                        parentRoute:
                                                                            routeProps.match.path,
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </>
                                            )}
                                    </div>
                                )}
                                <div className="ListPageLayout--body">
                                    <div className="ListPageLayout--body--content">
                                        {/* todo add ModalLauncher component */}
                                        {/* todo add HeaderAlerter component */}
                                        {(this.state.jobPickerData !== undefined ||
                                            !this.props.showJobPicker) && (
                                            <>
                                                {this.props.component(
                                                    {
                                                        key: this.state.keyInnerComponentUpdate, // This is a temporary solution to update stale data until we are able to use React Query across the application.
                                                        builderId:
                                                            this.props.builderInfo!.builderId!,
                                                        jobIds: filteredJobIds,
                                                        jobName: this.state.selectedJobName,
                                                        userId: this.props.userInfo!.globalUserId!,
                                                        isTemplateMode: this.state.templatesOnly,
                                                        loginType: this.state.loginPortalType,
                                                        jobPickerReady:
                                                            this.state.jobPickerData !== undefined,
                                                        setSelectedJobId: this.handleJobChangeById,
                                                        isAllJobsSelected:
                                                            this.state.selectedJobId ===
                                                                JobIdTypes.AllJobs ||
                                                            filteredJobIds.length > 1,
                                                        jobPickerCount: jobsInPicker,
                                                        setJobPickerCollapsed: this.handleCollapse,
                                                    },
                                                    matchProps
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <BTModal
                                    data-testid="btModalListPageLayout"
                                    width="1200px"
                                    visible={this.state.isFrozenBuilderNoticeVisible}
                                    beforeClose={() => {
                                        this.setState({
                                            frozenBuilderUserOverride: undefined,
                                            isFrozenBuilderNoticeVisible: false,
                                        });
                                    }}
                                    setPageTitle={false}
                                >
                                    <FrozenBuilderDialog
                                        isAccountClosed={this.state.isAccountClosed}
                                        overrideUserId={this.state.frozenBuilderUserOverride}
                                        {...matchProps}
                                    />
                                </BTModal>
                                <HotkeyDisplaySetup />
                            </div>
                        </>
                    );
                }}
            />
        );
    }
}

const defaultAppProviderHandler = new AppProviderHandler();
export const ListPageLayout: React.FC<IListPageLayoutProps> = ({
    appProviderHandler = defaultAppProviderHandler,
    ...props
}) => {
    return (
        <AppProvider handler={appProviderHandler}>
            <FocusProvider shareFocusWithParent>
                <ListPageLayoutContext {...props} />
            </FocusProvider>
        </AppProvider>
    );
};

interface IListPageLayoutInternalProps extends IListPageLayoutProps {
    builderInfo?: BuilderInfo | null | undefined;
    userInfo?: UserInfo;
    appDefaultInfo?: AppDefaultInfo;
    envInfo?: EnvironmentInfo;
}

export const ListPageLayoutContext: React.FC<IListPageLayoutProps> = (props) => {
    const appDefaultInfo = useContext(AppDefaultInfoContext);
    const builderInfo = useContext(BuilderInfoContext);
    const userInfo = useContext(UserInfoContext);
    const envInfo = useContext(EnvironmentInfoContext);

    return (
        <SpaInfoContext.Provider value={{ isSpa: true }}>
            <ListPageLayoutInternal
                {...props}
                appDefaultInfo={appDefaultInfo}
                builderInfo={builderInfo}
                userInfo={userInfo}
                envInfo={envInfo}
            />
        </SpaInfoContext.Provider>
    );
};
interface IFrozenBuilderDialogProps extends RouteComponentProps {
    isAccountClosed: boolean;
    overrideUserId?: number;
}

export const FrozenBuilderDialog = (props: IFrozenBuilderDialogProps) => {
    const builderInfo = useContext(BuilderInfoContext);
    const userInfo = useContext(UserInfoContext);
    return (
        <FrozenBuilder
            builderName={builderInfo?.name}
            encryptedBuilderId={builderInfo?.encryptedBuilderId!}
            isAdmin={userInfo?.isAdmin!}
            {...props}
        />
    );
};
